import ajax from '../plugins/ajax_request';
import * as ENV from "../libModules/constants";
export default {
    getCountryStoresActive() {
        return ajax.getCountryStoresActive();
    },
    // eslint-disable-next-line no-unused-vars
    getStores(state, cc) {
        return ajax.getStores(cc);
    },
    screenshotAndSendSendGridMail({ commit }, { data, emailFrom, emailTo }) {
        // Get a screenshot of the current canvas and send a SendGrid email
        // based on TemplateID and key specified in the environment variables.
        // After the operation, emailSent will be set to true or false to notify
        // whether the email has been sent successfully or not.
        commit("setShareConfiguration", { data, emailFrom, emailTo });
    },
    // eslint-disable-next-line no-unused-vars
    async sendSendGridMail(state, { data, emailFrom, emailTo }) {
        const res = await ajax.sendSendGridMail(data, emailFrom, emailTo, ENV.sendgridShareMailTemplateID, ENV.sendgridAPIKey);
        console.log("SEND MAIL", res);
    },
    async setIdForUrl({ state, getters }) {
        const json = JSON.stringify(getters.getActiveConfiguration);
        const res = await ajax.setCustomConfigurations(json);
        state.id_for_url = res.data;
        // state.qrcodeUrl = "http://localhost:8080/" + res.data;
        // state.qrcode = await getters.makeQRCode(res.data);
    },
    // eslint-disable-next-line no-unused-vars
    async getCustomConfigurations({ commit }, id) {
        const res = await ajax.getCustomConfigurations(id);
        console.log("getCustomConfigurations", res.data);
        commit("restoreJsonConfiguration", res.data);
    },
};
