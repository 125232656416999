import { cmesh, cmesh_1 } from "./scene";
import { textureLoader } from "./loaders";
import * as ENV from "../../libModules/constants";
import { render } from "./render";
import { frontMaterial } from "./materials";
import { acetateMaterialAttributes } from "./material_attributes";
import { textureThickness } from "./textures";
import { AdditiveBlending, CanvasTexture, Color, DataTexture, Group, LinearEncoding, LinearFilter, MeshPhongMaterial, MeshStandardMaterial, ObjectSpaceNormalMap, RepeatWrapping, RGBAFormat, RGBFormat, sRGBEncoding, Vector2, Vector3 } from "three/src/Three";
import { importantMeshPoints } from "./faceMesh";
import { GlitterMaterial } from "@/plugins/GlitterMaterial";
let glasses; // Group containing front and temples
let front;
// let frontMaterial!: Array<MeshPhysicalMaterial>;
let frontGeometry;
let frontTemplePositionDummy;
let frontTexture;
let frontTextureTransmission;
let frontTextureRoughness;
let frontTextureGlitter;
let frontTextureGlitterMap;
let frontTexturePearl;
let middleFrontalPoint;
let frontalInfo;
let distanceNose;
let currentFrontColor;
let currentFinitura;
let currentColorCategory;
function initGlasses() {
    glasses = new Group();
}
async function loadFrontModel(frontale, scale, finitura) {
    // If the glasses are not loaded yet, initialize them
    if (!glasses) {
        initGlasses();
    }
    glasses.remove(glasses.getObjectByName("FRONTALE"));
    frontGeometry?.dispose();
    try {
        if (frontale)
            front = frontale.scene.getObjectByName("FRONTALE");
        frontGeometry = front.geometry;
        frontGeometry.addGroup(0, Infinity, 0); // mteriale principale
        frontGeometry.addGroup(0, Infinity, 1); // materiale perlato (opzionale)
        frontGeometry.addGroup(0, Infinity, 2);
        // Mantieni informazioni sul materiale precedente
        const oldTransmission = frontMaterial[0] ? frontMaterial[0].transmission : acetateMaterialAttributes.transmission;
        const oldMap = frontMaterial[0] ? frontMaterial[0].map : frontTexture;
        const oldTransmissionMap = frontMaterial[0] ? frontMaterial[0].transmissionMap : frontTextureTransmission;
        const oldRoughnessMap = frontMaterial[0] ? frontMaterial[0].roughnessMap : frontTextureRoughness;
        front.castShadow = true;
        front.receiveShadow = true;
        front.renderOrder = 2;
        render.shadowMap.needsUpdate = true;
        if (!frontMaterial[0]) {
            frontMaterial[0] = front.material.clone();
        }
        frontMaterial[0].depthWrite = true;
        frontMaterial[0].color = acetateMaterialAttributes.color;
        frontMaterial[0].transmission =
            oldTransmission;
        if (frontTexture)
            frontMaterial[0].map = oldMap;
        if (frontTextureTransmission)
            frontMaterial[0].transmissionMap = oldTransmissionMap;
        if (frontTextureRoughness)
            frontMaterial[0].roughnessMap = oldRoughnessMap;
        frontMaterial[0].transparent =
            acetateMaterialAttributes.transparent;
        frontMaterial[0].roughness =
            acetateMaterialAttributes.roughness;
        frontMaterial[0].clearcoat =
            acetateMaterialAttributes.clearCoat;
        frontMaterial[0].reflectivity =
            acetateMaterialAttributes.reflectivity;
        frontMaterial[0].envMapIntensity =
            acetateMaterialAttributes.envMapIntensity;
        if (finitura == "M" || finitura == "N")
            frontMaterial[0].clearcoatRoughness =
                acetateMaterialAttributes.clearcoatRoughnessMatt;
        else
            frontMaterial[0].clearcoatRoughness =
                acetateMaterialAttributes.clearcoatRoughnessShiny;
        frontMaterial[0].ior = acetateMaterialAttributes.ior;
        frontMaterial[0].thickness =
            acetateMaterialAttributes.thickness;
        frontMaterial[0].metalness =
            acetateMaterialAttributes.metalness;
        frontMaterial[0].blending = acetateMaterialAttributes.blending;
        frontMaterial[0].sheen = 0.51;
        frontMaterial[0].sheenRoughness = 0.25;
        frontMaterial[0].sheenColor = new Color("rbg(0,0,0)");
        // frontMaterial[0].sheenColorMap = textureThickness;
        // frontMaterial[0].emissive = new Color("rgb(0, 0, 0)");
        textureThickness.anisotropy = 16;
        // textureThickness.format = LuminanceFormat;
        // textureThickness.type = HalfFloatType;
        textureThickness.encoding = sRGBEncoding;
        textureThickness.wrapS = RepeatWrapping;
        textureThickness.wrapT = RepeatWrapping;
        textureThickness.flipY = true;
        textureThickness.minFilter = LinearFilter;
        textureThickness.magFilter = LinearFilter;
        // textureThickness.needsUpdate = true;
        textureThickness.repeat.set(1, 1);
        frontMaterial[0].thicknessMap = textureThickness;
        frontMaterial[0].attenuationDistance = 25;
        frontMaterial[0].needsUpdate = true;
        // frontMaterial[1].visible = false;
        // frontMaterial[2].visible = false;
        front.material = frontMaterial;
        front.scale.set(scale.x, scale.y, scale.z);
        glasses.add(front);
    }
    catch (error) {
        console.error("[Canvas3D] Nesusn oggetto frontale caricato nella scena", error);
    }
    try {
        frontTemplePositionDummy = frontale.scene.getObjectByName("P1");
    }
    catch (error) {
        console.error("[Canvas3D] Nessun oggetto dummy caricato nella scena");
    }
}
async function setFrontalTexture(frontColor, finitura, colorCategory) {
    // let acetate = "PL0077";
    if (frontColor != currentFrontColor || finitura != currentFinitura || colorCategory != currentColorCategory) {
        frontTexture?.dispose();
        frontTextureTransmission?.dispose();
        frontTextureRoughness?.dispose();
        frontTextureGlitter?.dispose();
        frontTexturePearl?.dispose();
        if (frontColor) {
            currentFrontColor = frontColor;
            currentFinitura = finitura;
            currentColorCategory = colorCategory;
            let mainTexture;
            try {
                mainTexture = await textureLoader
                    .setRequestHeader({ "Cache-Control": "no-store" })
                    .loadAsync(
                // `images/${frontColor}.jpg`
                `${ENV.texturesUrl}${frontColor}.jpg`);
                mainTexture.magFilter = LinearFilter;
                mainTexture.anisotropy = 16;
                mainTexture.encoding = sRGBEncoding;
                mainTexture.wrapS = RepeatWrapping;
                mainTexture.wrapT = RepeatWrapping;
                mainTexture.rotation = Math.PI;
                mainTexture.repeat.set(1, 1);
                mainTexture.format = RGBFormat;
                frontTexture = mainTexture;
                frontMaterial[0].map = frontTexture;
            }
            catch (error) {
                console.error("[Canvas3d] Nessun colore corrispondente a ", frontColor, error);
            }
            try {
                const transmissionTexture = await textureLoader
                    .loadAsync(`${ENV.texturesUrl}transmission/${frontColor}.jpg`);
                // .loadAsync(`images/transmission/${frontColor}.jpg`)
                transmissionTexture.magFilter = LinearFilter;
                transmissionTexture.anisotropy = 16;
                transmissionTexture.encoding = LinearEncoding;
                // transmissionTexture.encoding = sRGBEncoding;
                transmissionTexture.wrapS = RepeatWrapping;
                transmissionTexture.wrapT = RepeatWrapping;
                transmissionTexture.rotation = Math.PI;
                transmissionTexture.repeat.set(1, 1);
                frontTextureTransmission = transmissionTexture;
                frontMaterial[0].transmissionMap = frontTextureTransmission;
                // Reduce the transmission when using the application, otherwise it is too strong and
                // for completely transparent materials it becomes impossible to understand their colors
                frontMaterial[0].transmission = ENV.isForApp ? 0.8 : 1.0;
                frontMaterial[0].opacity = 1;
                if (ENV.isForApp) {
                    // Get the base texture, we will use it to create the transmission map
                    // when using cfg-base-app from app
                    const cvsBase = document.createElement("canvas");
                    cvsBase.height = mainTexture.image.height;
                    cvsBase.width = mainTexture.image.width;
                    const ctxBase = cvsBase.getContext("2d");
                    ctxBase?.drawImage(mainTexture.image, 0, 0, mainTexture.image.width, mainTexture.image.height);
                    const imageBaseData = ctxBase?.getImageData(0, 0, mainTexture.image.width, mainTexture.image.height) || { data: [] };
                    // Get the image data of the texture
                    const cvs = document.createElement("canvas");
                    cvs.height = transmissionTexture.image.height;
                    cvs.width = transmissionTexture.image.width;
                    const ctx = cvs.getContext("2d");
                    ctx.drawImage(transmissionTexture.image, 0, 0);
                    const imageData = ctx.getImageData(0, 0, transmissionTexture.image.width, transmissionTexture.image.height) || { data: [] };
                    // Modify the image data of the texture: apparently when we are not using any background color, dark colors behave differently.
                    // In particular:
                    // - when there's a background color, the darker the colors, the less transparent the texture is
                    // - when there's no background color, it is not important how dark the colors are: the texture becomes transparent based on the transmission map only
                    // For each channel (r,g,b): reduce the value of the transmission based on how dark is the corresponding pixel on the base texture
                    for (let i = 0; i < imageData.data.length; i += 4) {
                        const med = (imageBaseData.data[i] + imageBaseData.data[i + 1] + imageBaseData.data[i + 2]) / 3;
                        imageData.data[i] = Math.max(0, imageData.data[i] - (frontMaterial[0].transmission * (255 - med)));
                        imageData.data[i + 1] = Math.max(0, imageData.data[i + 1] - (frontMaterial[0].transmission * (255 - med)));
                        imageData.data[i + 2] = Math.max(0, imageData.data[i + 2] - (frontMaterial[0].transmission * (255 - med)));
                    }
                    // Create a texture out of the constructed image data
                    const cvsTexture = new DataTexture(imageData.data, transmissionTexture.image.width, transmissionTexture.image.height, RGBAFormat);
                    cvsTexture.needsUpdate = true;
                    // Update the transmission map with the newly created one
                    frontTextureTransmission = cvsTexture;
                    frontMaterial[0].transmissionMap = cvsTexture;
                }
            }
            catch (error) {
                console.error(`[Canvas3D] Nessuna texture per il colore ${frontColor}`, error);
                frontTextureTransmission?.dispose();
                frontMaterial[0].transmissionMap = null;
                frontMaterial[0].transmission = 0;
                frontTexture.encoding = sRGBEncoding;
            }
            try {
                const roughnessTexture = await textureLoader
                    .loadAsync(`${ENV.texturesUrl}transmission/${frontColor}_roughness.jpg`);
                roughnessTexture.magFilter = LinearFilter;
                roughnessTexture.anisotropy = 16;
                // roughnessTexture.encoding = sRGBEncoding;
                roughnessTexture.encoding = LinearEncoding;
                roughnessTexture.wrapS = RepeatWrapping;
                roughnessTexture.wrapT = RepeatWrapping;
                roughnessTexture.rotation = Math.PI;
                roughnessTexture.repeat.set(1, 1);
                if (finitura == "M" || finitura == "N") {
                    const cvs = document.createElement("canvas");
                    cvs.height = roughnessTexture.image.height;
                    cvs.width = roughnessTexture.image.width;
                    const ctx = cvs.getContext("2d");
                    ctx?.drawImage(roughnessTexture.image, 0, 0, roughnessTexture.image.width, roughnessTexture.image.height);
                    const imageData = ctx?.getImageData(0, 0, roughnessTexture.image.width, roughnessTexture.image.height) || { data: [] };
                    for (let i = 0; i < imageData.data.length; i += 4) {
                        const red = imageData.data[i];
                        const green = imageData.data[i + 1];
                        const blu = imageData.data[i + 2];
                        if (red < 100) {
                            imageData.data[i] = Math.max(0, Math.min(255, red + acetateMaterialAttributes.addRoughnessPixel));
                            imageData.data[i + 1] = Math.max(0, Math.min(255, green + acetateMaterialAttributes.addRoughnessPixel));
                            imageData.data[i + 2] = Math.max(0, Math.min(255, blu + acetateMaterialAttributes.addRoughnessPixel));
                        }
                        else {
                            imageData.data[i] = Math.max(0, Math.min(255, red + acetateMaterialAttributes.addRoughnessPixel / 3.5));
                            imageData.data[i + 1] = Math.max(0, Math.min(255, green + acetateMaterialAttributes.addRoughnessPixel / 3.5));
                            imageData.data[i + 2] = Math.max(0, Math.min(255, blu + acetateMaterialAttributes.addRoughnessPixel / 3.5));
                        }
                    }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    ctx?.putImageData(imageData, 0, 0);
                    const cvsTexture = new CanvasTexture(cvs);
                    frontMaterial[0].roughnessMap = cvsTexture;
                }
                else {
                    frontTextureRoughness = roughnessTexture;
                    frontMaterial[0].roughnessMap = frontTextureRoughness;
                }
            }
            catch (error) {
                console.warn("[Canvas3D] Nessuna texture roughness pe il colore ", frontColor, error);
                frontMaterial[0].roughnessMap = null;
            }
            try {
                const normalsTexture = await textureLoader
                    .loadAsync(`${ENV.texturesUrl}transmission/${frontColor}_normal.jpg`);
                normalsTexture.anisotropy = 16;
                normalsTexture.encoding = sRGBEncoding;
                normalsTexture.wrapS = RepeatWrapping;
                normalsTexture.wrapT = RepeatWrapping;
                normalsTexture.repeat.set(2, 2);
                frontMaterial[1] = new MeshStandardMaterial({
                    blending: AdditiveBlending,
                    color: 0x000000,
                    // specular: 0x5f5f5f,
                    roughness: 0.2,
                    transparent: true,
                    opacity: 0.3,
                    normalMap: normalsTexture,
                    normalScale: new Vector2(1, 1),
                    normalMapType: ObjectSpaceNormalMap,
                });
            }
            catch (error) {
                console.warn("[Canvas3D] Nessuna texture pearl per il colore ", frontColor, error);
                frontMaterial[1]?.dispose();
                frontMaterial[1] = new MeshStandardMaterial();
                frontMaterial[1].visible = false;
            }
            if (colorCategory && colorCategory == "GLITTER") {
                const glitterTexture = await textureLoader
                    .setRequestHeader({ "Cache-Control": "no-store" })
                    .loadAsync(`${ENV.texturesUrl}${frontColor}_glitter.jpg`);
                // acquisiamo i dati della texture
                glitterTexture.encoding = LinearEncoding;
                const imagedata = getImageData(glitterTexture.image);
                const densityPick = getPixel(imagedata, 8, 8);
                const radiusPick = getPixel(imagedata, 24, 8);
                const variancePick = getPixel(imagedata, 8, 24);
                const colorPick = getPixel(imagedata, 24, 24);
                const glitColor = new Color(colorPick.r, colorPick.g, colorPick.b);
                const glitDensity = (1 / (255 / densityPick.r));
                const glitRadius = (1 / (255 / radiusPick.r));
                const glitVariance = (1 / (255 / variancePick.r));
                const customUniforms = {
                    uGlitterSize: { value: glitRadius / 30 },
                    uGlitterDensity: { value: glitDensity / 4 },
                    uGlitterColor: { value: new Vector3(glitColor.r / 255, glitColor.g / 255, glitColor.b / 255) },
                };
                frontMaterial[2] = new GlitterMaterial(customUniforms, {
                    transparent: true,
                });
            }
            else {
                frontMaterial[2]?.dispose();
                frontMaterial[2] = new MeshPhongMaterial();
                frontMaterial[2].visible = false;
            }
            frontMaterial[0].needsUpdate = true;
            frontMaterial[1].needsUpdate = true;
            frontMaterial[2].needsUpdate = true;
        }
    }
}
async function changeFinitura(newVal, frontColor) {
    try {
        const roughnessTexture = await textureLoader.loadAsync(`${ENV.texturesUrl}transmission/${frontColor}_roughness.jpg`);
        roughnessTexture.magFilter = LinearFilter;
        roughnessTexture.anisotropy = 16;
        roughnessTexture.encoding = sRGBEncoding;
        roughnessTexture.wrapS = RepeatWrapping;
        roughnessTexture.wrapT = RepeatWrapping;
        roughnessTexture.rotation = Math.PI;
        roughnessTexture.repeat.set(1, 1);
        if (newVal == "M" || newVal == "N") {
            const cvs = document.createElement("canvas");
            cvs.height = roughnessTexture.image.height;
            cvs.width = roughnessTexture.image.width;
            // let gui = document.getElementById("gui");
            // gui?.appendChild(cvs);
            const ctx = cvs.getContext("2d");
            ctx?.drawImage(roughnessTexture.image, 0, 0, roughnessTexture.image.width, roughnessTexture.image.height);
            const imageData = ctx?.getImageData(0, 0, roughnessTexture.image.width, roughnessTexture.image.height) || { data: [] };
            for (let i = 0; i < imageData.data.length; i += 4) {
                const red = imageData.data[i];
                const green = imageData.data[i + 1];
                const blu = imageData.data[i + 2];
                if (red < 100) {
                    imageData.data[i] = Math.max(0, Math.min(255, red + acetateMaterialAttributes.addRoughnessPixel));
                    imageData.data[i + 1] = Math.max(0, Math.min(255, green + acetateMaterialAttributes.addRoughnessPixel));
                    imageData.data[i + 2] = Math.max(0, Math.min(255, blu + acetateMaterialAttributes.addRoughnessPixel));
                }
                else {
                    imageData.data[i] = Math.max(0, Math.min(255, red + acetateMaterialAttributes.addRoughnessPixel / 3.5));
                    imageData.data[i + 1] = Math.max(0, Math.min(255, green + acetateMaterialAttributes.addRoughnessPixel / 3.5));
                    imageData.data[i + 2] = Math.max(0, Math.min(255, blu + acetateMaterialAttributes.addRoughnessPixel / 3.5));
                }
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            ctx?.putImageData(imageData, 0, 0);
            const cvsTexture = new CanvasTexture(cvs);
            frontMaterial[0].roughnessMap = cvsTexture;
            frontMaterial[0].clearcoatRoughness =
                acetateMaterialAttributes.clearcoatRoughnessMatt;
        }
        else {
            frontTextureRoughness = roughnessTexture;
            frontMaterial[0].roughnessMap = frontTextureRoughness;
            frontMaterial[0].clearcoatRoughness =
                acetateMaterialAttributes.clearcoatRoughnessShiny;
        }
    }
    catch (error) {
        console.warn("[Canvas3D] Nessuna texture roughness pe il colore ", frontColor, error);
        frontMaterial[0].roughnessMap = null;
    }
    if (newVal == "M" || newVal == "N") {
        frontMaterial[0].clearcoatRoughness =
            acetateMaterialAttributes.clearcoatRoughnessMatt;
    }
    else {
        frontMaterial[0].clearcoatRoughness =
            acetateMaterialAttributes.clearcoatRoughnessShiny;
    }
}
function setFrontalePosition(newVal, distanceNose) {
    if (front != null) {
        let closerIndex = 0;
        let closerDistance = Number.POSITIVE_INFINITY;
        const value = importantMeshPoints[3][1] - 5;
        for (let i = 6; i < importantMeshPoints.length; i += 3) {
            const distance = Math.abs(Math.abs(value) - Math.abs(importantMeshPoints[i][1]));
            if (!closerIndex || closerDistance > distance) {
                closerIndex = i;
                closerDistance = distance;
            }
        }
        // Calcoli per abbassare/alzare l'occhiale lungo il naso in base alla calzata scelta
        const p1 = new Vector3(importantMeshPoints[3][0], importantMeshPoints[3][1], importantMeshPoints[3][2]);
        const p2 = new Vector3(importantMeshPoints[9][0], importantMeshPoints[9][1], importantMeshPoints[9][2]);
        const b = p2.z - p1.z;
        const c = Math.abs(p1.y - p2.y);
        const a = Math.pow(Math.pow(c, 2) + Math.pow(b, 2), 0.5);
        // eslint-disable-next-line no-unused-vars
        const noseAngle = Math.asin(b / a);
        console.log(distanceNose);
        // // eslint-disable-next-line no-unused-vars
        const diff = (distanceNose - Number(newVal.calzata)) * 2.5; // 2.5 calcolato su modelli veri provati sull'applicazione
        const newX = p1.x;
        const newY = diff * Math.cos(noseAngle);
        const newZ = -1 * diff * Math.sin(noseAngle);
        // Togliamo middleFrontalPoint perché lo 0 del frontale sulla y si trova nel lato in basso, invece
        // vogliamo che si trovi al centro della lente e spostato verso il basso di 5 millimetri (il conto è fatto lato flutter)
        // Togliamo di nuovo 5 millimetri perché ci spostiamo 5 mm sotto l'asse ottico
        const x = importantMeshPoints[3][0] + newX;
        const y = importantMeshPoints[3][1] - newVal.middleFrontalPoint[1] - 5 + newY;
        let z = 0;
        if (y < importantMeshPoints[3][1]) {
            z = importantMeshPoints[closerIndex + 1][2] + 3;
        }
        else {
            z = importantMeshPoints[closerIndex + 1][2] + newZ + 3;
        }
        console.log(x, y, z);
        glasses.position.x = x;
        glasses.position.y = y;
        glasses.position.z = z;
        if (front.geometry.attributes.position)
            front.geometry.attributes.position.needsUpdate = true;
        cmesh.position.z = importantMeshPoints[closerIndex + 1][2] - 35;
        cmesh_1.position.z =
            importantMeshPoints[closerIndex + 1][2] - 145;
        cmesh.geometry.attributes.position.needsUpdate = true;
        cmesh_1.geometry.attributes.position.needsUpdate = true;
    }
}
function getImageData(image) {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext("2d");
    context?.drawImage(image, 0, 0);
    return context?.getImageData(0, 0, image.width, image.height);
}
// prendiamo un pixel della texture per utilizzarlo successivamente
function getPixel(imagedata, x, y) {
    const position = (x + imagedata.width * y) * 4, data = imagedata.data;
    return {
        r: data[position],
        g: data[position + 1],
        b: data[position + 2],
        a: data[position + 3],
    };
}
export { glasses, front, frontGeometry, frontTemplePositionDummy, frontTexture, frontTextureTransmission, frontTextureRoughness, frontTextureGlitter, frontTextureGlitterMap, frontTexturePearl, middleFrontalPoint, frontalInfo, distanceNose, loadFrontModel, setFrontalTexture, changeFinitura, setFrontalePosition, initGlasses };
