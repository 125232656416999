import { AdditiveBlending, Color, FrontSide, NormalBlending } from "three/src/Three";
const acetateMaterialAttributes = {
    depthWrite: true,
    color: new Color(0xffffff).convertSRGBToLinear(),
    roughness: 1,
    reflectivity: 0.35,
    // alphaToCoverage: true,
    clearCoat: 1,
    clearcoatRoughnessShiny: 0,
    clearcoatRoughnessMatt: 0.5,
    transmission: 0,
    transparent: false,
    blending: NormalBlending,
    ior: 1.5,
    thickness: 0.4,
    metalness: 0.1,
    envMapIntensity: 0.3,
    addRoughnessPixel: 40,
    sheen: 0.25,
};
const lensesMaterialAttribute = {
    depthWrite: true,
    color: new Color(0x000000).convertSRGBToLinear(),
    envMapIntensity: 0.1,
    roughness: 0,
    reflectivity: 0.1,
    clearCoat: 1,
    clearcoatRoughness: 0,
    emissiveIntensity: 0,
    side: FrontSide,
    // emissiveColor: new Color(0xe0e0e0),
    transmission: 1,
    transparent: true,
    blending: AdditiveBlending,
    // Valore precedente ior: 1.55. Cambiato per errori di visualizzazione
    ior: 1,
    thickness: 0.1,
    metalness: 0.3,
};
export { acetateMaterialAttributes, lensesMaterialAttribute };
