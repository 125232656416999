import ajax from "../../plugins/ajax_request";
import * as ENV from '../../libModules/constants';
// const state = () => ({
class Cart {
    constructor() {
        this.cartRows = [];
        this.configurations = [];
        this.checkoutSuccess = false;
        this.addToCartSuccess = false;
        this.checkingOut = false;
    }
}
// const state: CartState = {
//   cartRows: [],
//   configurations: [],
//   checkoutSuccess: false,
//   checkingOut: false
// }
const getters = {
    canAddToCart: (state, getters, rootState, rootGetters) => {
        // const guest = (rootState.configurator.user) ? rootState.configurator.user?.user != null : true;
        const guest = rootGetters['configurator/user/isGuest'];
        const configurationComplete = rootGetters['configurator/configurationComplete'];
        return configurationComplete && !guest ? true : false;
    },
    rowNumber: (state) => state.cartRows.length,
};
const mutations = {
    setCartRows(state, rows) {
        state.cartRows = rows;
    },
    /*Cart mutations*/
    emptyCart: (state) => {
        state.cartRows = [];
    },
    setCheckoutStatus: (state, status) => {
        state.checkoutSuccess = status;
    },
    setAddToCartStatus: (state, status) => {
        state.addToCartSuccess = status;
    },
    setCheckingOut: (state, s) => {
        state.checkingOut = s;
    },
};
const actions = {
    addToCart({ commit, rootState }) {
        const rows = [];
        if (rootState.configurator.frontali && rootState.configurator.frontale && rootState.configurator.coloreAste && rootState.configurator.colore && rootState.configurator.aste && rootState.configurator.lenti) {
            const row = {
                ItemCode: rootState.configurator.frontale.ItemCode,
                colore_frontale: rootState.configurator.colore.U_THE_COLORE,
                quantita: 1,
                note: rootState.configurator.note,
                TempleCode: rootState.configurator.coloreAste.ItemCode,
                abbellitore: rootState.configurator.abbellitore,
                FinishingTemple: rootState.configurator.finituraAste,
                calibro: parseInt(rootState.configurator.calibro),
                naso: parseInt(rootState.configurator.naso),
                finishing: rootState.configurator.finitura,
                asian_fitting: rootState.configurator.asianFitting ? 1 : 0,
                colore_nome: rootState.configurator.frontali.name && rootState.configurator.frontali.name.length > 0 ? rootState.configurator.frontali.nameColor : "",
                font_nome: rootState.configurator.frontali.name && rootState.configurator.frontali.name.length > 0 ? rootState.configurator.frontali.nameFont : "Batang",
                nome: rootState.configurator.frontali.name,
                coloreMetalloAste: rootState.configurator.coloreMetalloAste ? rootState.configurator.coloreMetalloAste : '',
                nomeExtAstaSx: rootState.configurator.aste.extSxName,
                coloreNomeExtAstaSx: rootState.configurator.aste.extSxNameColor,
                fontNomeExtAstaSx: rootState.configurator.aste.extSxName && rootState.configurator.aste.extSxName.length > 0 ? rootState.configurator.aste.extSxNameFont : "Batang",
                nomeIntAstaSx: rootState.configurator.aste.intSxName,
                coloreNomeIntAstaSx: rootState.configurator.aste.intSxNameColor,
                fontNomeIntAstaSx: rootState.configurator.aste.intSxName && rootState.configurator.aste.intSxName.length > 0 ? rootState.configurator.aste.intSxNameFont : "Batang",
                coloreNomeIntAstaDx: rootState.configurator.aste.intDxNameColor,
                nomeIntAstaDx: rootState.configurator.aste.intDxName,
                fontNomeIntAstaDx: rootState.configurator.aste.intDxName && rootState.configurator.aste.intDxName.length > 0 ? rootState.configurator.aste.intDxNameFont : "Batang",
                nomeExtAstaDx: rootState.configurator.aste.extDxName,
                coloreNomeExtAstaDx: rootState.configurator.aste.extDxNameColor,
                fontNomeExtAstaDx: rootState.configurator.aste.extDxName && rootState.configurator.aste.extDxName.length > 0 ? rootState.configurator.aste.extDxNameFont : "Batang",
                lensesType: 'MONOFOCALE',
                lensdescrDx: rootState.configurator.lenteVistaDx ? rootState.configurator.lenteVistaDx.U_THE_MODELLO : '',
                lensdescrSx: rootState.configurator.lenteVistaSx ? rootState.configurator.lenteVistaSx.U_THE_MODELLO : '',
                lensDx: rootState.configurator.lenteVistaDx ? rootState.configurator.lenteVistaDx.ItemCode : '',
                lensSx: rootState.configurator.lenteVistaSx ? rootState.configurator.lenteVistaSx.ItemCode : '',
                lensSole: rootState.configurator.lenteSole ? rootState.configurator.lenteSole.ItemCode : '',
                sferaDx: rootState.configurator.lenti.sferaDx,
                sferaSx: rootState.configurator.lenti.sferaSx,
                cilindroDx: rootState.configurator.lenti.cilindroDx,
                cilindroSx: rootState.configurator.lenti.cilindroSx,
                asseDx: rootState.configurator.lenti.asseDx,
                asseSx: rootState.configurator.lenti.asseSx,
                semiDistDx: rootState.configurator.lenti.semiDistDx,
                semiDistSx: rootState.configurator.lenti.semiDistSx,
                altPupDx: rootState.configurator.lenti.altPupDx,
                altPupSx: rootState.configurator.lenti.altPupSx,
                addDx: rootState.configurator.lenti.addDx,
                addSx: rootState.configurator.lenti.addSx,
            };
            rows.push(row);
            return ajax.addToCart(rows).then((response) => {
                const d = response.data;
                commit("setAddToCartStatus", d.status.success);
                if (d.status.success && rootState.configurator.frontali && rootState.configurator.aste) {
                    const gtagItems = [];
                    rows.forEach((row) => {
                        if (rootState.configurator.frontali) {
                            const i = rootState.configurator.frontali.items.filter((frontale) => {
                                return frontale.ItemCode == row.ItemCode;
                            })[0];
                            const temp = {
                                id: i.ItemCode,
                                name: i.ItemName,
                                brand: i.U_THE_LINEA,
                                variant: `${i.U_THE_MODELLO} ${row.calibro}/${row.naso} ${row.colore_frontale} - ${row.finishing}`,
                                quantity: row.quantita,
                                aste: rootState.configurator.coloreAste?.ItemName,
                                coloreMetalloAste: rootState.configurator.coloreMetalloAste,
                                finitura: rootState.configurator.finitura == "S" ? "LUCIDO" : rootState.configurator.finitura == "N" ? "NATURALE" : "OPACO",
                                asianFitting: rootState.configurator.asianFitting ? "SI" : "NO",
                                coloreFrontale: row.colore_frontale,
                                misura: `${row.calibro}/${row.naso}`,
                                finituraAste: rootState.configurator.finituraAste == "S" ? "LUCIDO" : "OPACO",
                                nomePersonalizzato: row.colore_nome,
                                coloreNomePersonalizzato: row.font_nome,
                                nomeExtAstaSx: row.nomeExtAstaSx,
                                coloreNomeExtAstaSx: row.coloreNomeExtAstaSx,
                                nomeIntAstaSx: row.nomeIntAstaSx,
                                coloreNomeIntAstaSx: row.coloreNomeIntAstaSx,
                                coloreNomeIntAstaDx: row.coloreNomeIntAstaDx,
                                nomeIntAstaDx: row.nomeIntAstaDx,
                                nomeExtAstaDx: row.nomeExtAstaDx,
                                coloreNomeExtAstaDx: row.coloreNomeExtAstaDx,
                            };
                            gtagItems.push(temp);
                        }
                    });
                    if (ENV.gtagId) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // eslint-disable-next-line no-undef
                        gtag("event", "add_to_cart", { items: gtagItems, 'send_to': ENV.gtagId });
                    }
                    rootState.configurator.note = "";
                    rootState.configurator.lenteVistaDx = null;
                    rootState.configurator.lenteVistaSx = null;
                    rootState.configurator.lenti.sferaDx = 0;
                    rootState.configurator.lenti.cilindroDx = 0;
                    rootState.configurator.lenti.asseDx = 0;
                    rootState.configurator.lenti.semiDistDx = 0;
                    rootState.configurator.lenti.altPupDx = 0;
                    rootState.configurator.lenti.addDx = 0;
                    rootState.configurator.lenti.sferaSx = 0;
                    rootState.configurator.lenti.cilindroSx = 0;
                    rootState.configurator.lenti.asseSx = 0;
                    rootState.configurator.lenti.semiDistSx = 0;
                    rootState.configurator.lenti.altPupSx = 0;
                    rootState.configurator.lenti.addSx = 0;
                    rootState.configurator.frontali.name = "";
                    rootState.configurator.frontali.nameColor = "";
                    rootState.configurator.frontali.nameFont = "Batang";
                    rootState.configurator.aste.extSxName = '';
                    rootState.configurator.aste.extSxNameColor = '';
                    rootState.configurator.aste.extSxNameFont = 'Batang';
                    rootState.configurator.aste.intSxName = '';
                    rootState.configurator.aste.intSxNameColor = '';
                    rootState.configurator.aste.intSxNameFont = 'Batang';
                    rootState.configurator.aste.extDxName = '';
                    rootState.configurator.aste.extDxNameColor = '';
                    rootState.configurator.aste.extDxNameFont = 'Batang';
                    rootState.configurator.aste.intDxName = '';
                    rootState.configurator.aste.intDxNameColor = '';
                    rootState.configurator.aste.intDxNameFont = 'Batang';
                    commit("setCartRows", d.cart); //status.cartRows = d.cart;
                }
            });
        }
    },
    addFrontaliniMeniscatiToCart({ commit, rootState }) {
        const rows = [];
        if (rootState.configurator.frontali && rootState.configurator.frontale && rootState.configurator.colore) {
            const row = {
                ItemCode: rootState.configurator.frontale.ItemCode,
                colore_frontale: rootState.configurator.colore.U_THE_COLORE,
                quantita: 1,
                note: rootState.configurator.note,
                TempleCode: null,
                abbellitore: rootState.configurator.abbellitore,
                FinishingTemple: null,
                calibro: parseInt(rootState.configurator.calibro),
                naso: parseInt(rootState.configurator.naso),
                finishing: rootState.configurator.finitura,
                asian_fitting: 0,
                colore_nome: rootState.configurator.frontali.name && rootState.configurator.frontali.name.length > 0 ? rootState.configurator.frontali.nameColor : "",
                font_nome: rootState.configurator.frontali.name && rootState.configurator.frontali.name.length > 0 ? rootState.configurator.frontali.nameFont : "Batang",
                nome: rootState.configurator.frontali.name,
                coloreMetalloAste: null,
                nomeExtAstaSx: "",
                coloreNomeExtAstaSx: "",
                fontNomeExtAstaSx: "",
                nomeIntAstaSx: "",
                coloreNomeIntAstaSx: "",
                fontNomeIntAstaSx: "",
                coloreNomeIntAstaDx: "",
                nomeIntAstaDx: "",
                fontNomeIntAstaDx: "",
                nomeExtAstaDx: "",
                coloreNomeExtAstaDx: "",
                fontNomeExtAstaDx: "",
                lensesType: 'MONOFOCALE',
                lensdescrDx: rootState.configurator.lenteVistaDx ? rootState.configurator.lenteVistaDx.U_THE_MODELLO : '',
                lensdescrSx: rootState.configurator.lenteVistaSx ? rootState.configurator.lenteVistaSx.U_THE_MODELLO : '',
                lensDx: rootState.configurator.lenteVistaDx ? rootState.configurator.lenteVistaDx.ItemCode : '',
                lensSx: rootState.configurator.lenteVistaSx ? rootState.configurator.lenteVistaSx.ItemCode : '',
                lensSole: rootState.configurator.lenteSole ? rootState.configurator.lenteSole.ItemCode : '',
                sferaDx: 0.0,
                sferaSx: 0.0,
                cilindroDx: 0.0,
                cilindroSx: 0.0,
                asseDx: 0.0,
                asseSx: 0.0,
                semiDistDx: 0.0,
                semiDistSx: 0.0,
                altPupDx: 0.0,
                altPupSx: 0.0,
                addDx: 0.0,
                addSx: 0.0,
            };
            rows.push(row);
            rootState.configurator.note = "";
            return ajax.addToCart(rows).then((response) => {
                const d = response.data;
                commit("setAddToCartStatus", d.status.success);
                if (d.status.success && rootState.configurator.frontali && rootState.configurator.aste) {
                    const gtagItems = [];
                    rows.forEach((row) => {
                        if (rootState.configurator.frontali) {
                            const i = rootState.configurator.frontali.items.filter((frontale) => {
                                return frontale.ItemCode == row.ItemCode;
                            })[0];
                            const temp = {
                                id: i.ItemCode,
                                name: i.ItemName,
                                brand: i.U_THE_LINEA,
                                variant: `${i.U_THE_MODELLO} ${row.calibro}/${row.naso} ${row.colore_frontale} - ${row.finishing}`,
                                quantity: row.quantita,
                                aste: rootState.configurator.coloreAste?.ItemName,
                                coloreMetalloAste: rootState.configurator.coloreMetalloAste,
                                finitura: rootState.configurator.finitura == "S" ? "LUCIDO" : rootState.configurator.finitura == "N" ? "NATURALE" : "OPACO",
                                asianFitting: rootState.configurator.asianFitting ? "SI" : "NO",
                                coloreFrontale: row.colore_frontale,
                                misura: `${row.calibro}/${row.naso}`,
                                finituraAste: rootState.configurator.finituraAste == "S" ? "LUCIDO" : "OPACO",
                                nomePersonalizzato: row.colore_nome,
                                coloreNomePersonalizzato: row.font_nome,
                                nomeExtAstaSx: row.nomeExtAstaSx,
                                coloreNomeExtAstaSx: row.coloreNomeExtAstaSx,
                                nomeIntAstaSx: row.nomeIntAstaSx,
                                coloreNomeIntAstaSx: row.coloreNomeIntAstaSx,
                                coloreNomeIntAstaDx: row.coloreNomeIntAstaDx,
                                nomeIntAstaDx: row.nomeIntAstaDx,
                                nomeExtAstaDx: row.nomeExtAstaDx,
                                coloreNomeExtAstaDx: row.coloreNomeExtAstaDx,
                            };
                            gtagItems.push(temp);
                        }
                    });
                    if (ENV.gtagId) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // eslint-disable-next-line no-undef
                        gtag("event", "add_to_cart", { items: gtagItems, 'send_to': ENV.gtagId });
                    }
                    rootState.configurator.frontali.name = "";
                    rootState.configurator.frontali.nameColor = "";
                    rootState.configurator.frontali.nameFont = "Batang";
                    rootState.configurator.aste.extSxName = '';
                    rootState.configurator.aste.extSxNameColor = '';
                    rootState.configurator.aste.extSxNameFont = 'Batang';
                    rootState.configurator.aste.intSxName = '';
                    rootState.configurator.aste.intSxNameColor = '';
                    rootState.configurator.aste.intSxNameFont = 'Batang';
                    rootState.configurator.aste.extDxName = '';
                    rootState.configurator.aste.extDxNameColor = '';
                    rootState.configurator.aste.extDxNameFont = 'Batang';
                    rootState.configurator.aste.intDxName = '';
                    rootState.configurator.aste.intDxNameColor = '';
                    rootState.configurator.aste.intDxNameFont = 'Batang';
                    commit("setCartRows", d.cart); //status.cartRows = d.cart;
                }
            });
        }
    },
    addFrontaliniToCart({ commit }, selectedColors) {
        const rows = [];
        selectedColors.forEach((color) => {
            const row = {
                ItemCode: "PRO0000480",
                colore_frontale: color,
                quantita: 1,
                note: selectedColors.length > 30 ? "KIT COLOR" : "",
                TempleCode: null,
                FinishingTemple: null,
                calibro: null,
                naso: null,
                finishing: null,
                asian_fitting: 0,
                colore_nome: "",
                font_nome: "",
                nome: "",
                coloreMetalloAste: "",
                nomeExtAstaSx: '',
                coloreNomeExtAstaSx: '',
                fontNomeExtAstaSx: '',
                nomeIntAstaSx: '',
                coloreNomeIntAstaSx: '',
                fontNomeIntAstaSx: '',
                coloreNomeIntAstaDx: '',
                nomeIntAstaDx: '',
                fontNomeIntAstaDx: '',
                nomeExtAstaDx: '',
                coloreNomeExtAstaDx: '',
                fontNomeExtAstaDx: '',
                lensesType: '',
                lensdescrDx: '',
                lensdescrSx: '',
                lensDx: '',
                lensSx: '',
                lensSole: '',
                sferaDx: 0,
                sferaSx: 0,
                cilindroDx: 0,
                cilindroSx: 0,
                asseDx: 0,
                asseSx: 0,
                semiDistDx: 0,
                semiDistSx: 0,
                altPupDx: 0,
                altPupSx: 0,
                addDx: 0,
                addSx: 0,
                abbellitore: null
            };
            rows.push(row);
        });
        return ajax.addToCart(rows).then((response) => {
            const d = response.data;
            commit("setAddToCartStatus", d.status.success);
            if (d.status.success) {
                commit("setCartRows", d.cart);
            }
        });
    },
    addSampleToCart({ commit, rootState }, rows) {
        // let auth_key = Vue.$cookies.get("_identity");
        return ajax.addToCart(rows).then((response) => {
            const d = response.data;
            commit("setAddToCartStatus", d.status.success);
            if (d.status.success && rootState.configurator.frontali) {
                rootState.configurator.frontali.name = "";
                rootState.configurator.frontali.nameColor = "";
                rootState.configurator.frontali.nameFont = "Batang";
                commit("setCartRows", d.cart); //status.cartRows = d.cart;
            }
        });
    },
    deleteCartRow({ commit }, id) {
        // let auth_key = Vue.$cookies.get("_identity");
        return ajax.deleteCartRow(id).then((response) => {
            const d = response.data;
            if (d.status == true) {
                commit("setCartRows", d.cart); //status.cartRows = d.cart;
            }
        });
    },
    emptyCart({ commit }) {
        return ajax.emptyUserCart().then((response) => {
            const d = response.data;
            if (d == true) {
                commit("emptyCart");
            }
        });
    },
    checkout({ commit, rootState }, note) {
        commit("setCheckingOut", true);
        return ajax.checkout(note).then((response) => {
            const d = response.data;
            if (parseInt(d.order_id) > 0) {
                const items = [];
                rootState.configurator.cart?.cartRows.forEach((row) => {
                    const i = rootState.configurator.frontali?.items.filter((frontale) => {
                        return frontale.ItemCode == row.ItemCode;
                    })[0];
                    const a = rootState.configurator.aste?.allColors.filter((asta) => {
                        return asta.ItemCode == row.TempleCode;
                    })[0];
                    if (i) {
                        const temp = {
                            id: i.ItemCode,
                            name: i.ItemName,
                            brand: i.U_THE_LINEA,
                            variant: `${i.U_THE_MODELLO} ${row.calibro}/${row.naso} ${row.colore_frontale} - ${row.finishing}`,
                            quantity: row.quantita,
                            aste: a ? a.ItemName : "",
                            coloreMetalloAste: a && a.U_THE_COLORE.indexOf("GUN") >= 0 ? "GUN" : "GOLD",
                            finitura: row.finishing,
                            asianFitting: row.asian_fitting == 0 ? "NO" : "SI",
                            coloreFrontale: row.colore_frontale,
                            misura: `${row.calibro}/${row.naso}`,
                            finituraAste: row.FinishingTemple == "M" ? "OPACO" : "LUCIDO",
                            nomePersonalizzato: row.nome,
                            coloreNomePersonalizzato: row.colore_nome,
                            nomeExtAstaSx: row.nomeExtAstaSx,
                            coloreNomeExtAstaSx: row.coloreNomeExtAstaDx,
                            nomeIntAstaSx: row.nomeIntAstaSx,
                            coloreNomeIntAstaSx: row.coloreNomeIntAstaSx,
                            nomeExtAstaDx: row.nomeExtAstaDx,
                            coloreNomeExtAstaDx: row.coloreNomeExtAstaDx,
                            nomeIntAstaDx: row.nomeIntAstaDx,
                            coloreNomeIntAstaDx: row.coloreNomeIntAstaDx,
                        };
                        items.push(temp);
                    }
                });
                if (ENV.gtagId) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line no-undef
                    gtag("event", "purchase", {
                        transaction_id: d.order_id,
                        affiliation: "Configuratore " + ENV.configIdentifier,
                        items: items,
                        'send_to': ENV.gtagId
                    });
                }
                commit("setCheckoutStatus", true);
                commit("setCheckingOut", false);
                commit("emptyCart");
            }
            else {
                commit("setCheckoutStatus", false);
            }
        });
    },
};
export const cart = {
    namespaced: true,
    state: new Cart(),
    getters,
    mutations,
    actions,
};
