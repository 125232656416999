//  @filename index.js
import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
// import getters from "./getters";
import getters from "./getters";
import actions from "./actions";
import { cart } from "./modules/cart";
import { user } from "./modules/user";
import { aste } from "./modules/aste";
import { frontali } from "./modules/frontali";
import { lenti } from "./modules/lenti";
import pack from '../../package.json';
Vue.use(Vuex);
const state = {
    frontale: null,
    calibro: "",
    naso: "",
    glassType: "S",
    asianFitting: false,
    colore: null,
    finitura: "S",
    coloreAste: null,
    coloreMetalloAste: "PRO0000272",
    finituraAste: "S",
    templesMaterial: "PL",
    defaultColors: {},
    overrideLinea: "",
    lenteSole: null,
    lenteVistaSx: null,
    lenteVistaDx: null,
    misuraAste: "",
    // Definisco di che tipo è l'abbellitore A tipo classico, B variante
    tipoAbbellitore: "A",
    // PRO0000849    PT/ ABLT - 9 ABBELLITORE ALPACCA PROG.SILMAASEMA GUN
    // PRO0000850    PT / ABLT - 9 ABBELLITORE ALPACCA PROG.SILMAASEMA GOLD 
    // nel caso di null è l'abbellitore esagonale
    abbellitore: null,
    confirm: false,
    quantity: 1,
    id_for_url: 0,
    qrcode: "",
    qrcodeUrl: "",
    loadingGeometry: false,
    //:artRows: [],
    configurations: [],
    animateToFront: false,
    animateToTemple: false,
    // user: {},
    // checkoutSuccess: false,
    // checkingOut: false, //true quando in attesa di risposta per checkout
    // frontali: [], // raccoglie i dati dei frontali comprensivi di prezzi
    // aste: [],
    note: "",
    version: pack.version,
    userInfo: {
        distancePup: 0,
        distanceTemp: 0,
        distanceNose: 0
    },
    meshVertexTriangles: {
        fov: 0,
        landmarks_index: []
    },
    rotationMatrix: [],
    frontalInfo: {
        "id": "242",
        "MODELLO": "OV-807",
        "lensSize": "56.00",
        "calzata": "17.10",
        "DBL": "16.00",
        "NOSE": "17.00",
        "verticalLensSize": "50.60",
        "ED": "61.80",
        "HINGE_TO_HINGE": "134.000",
        "TemplePositionX": "-16.129",
        "TemplePositionY": "-66.941",
        "TemplePositionZ": "20.148",
        "AxisVectorX": "-14.671",
        "AxisVectorY": "-66.878",
        "AxisVectorZ": "19.799",
        "Material": "",
        "Shape": "",
        "Type": "",
        "Near-Sighted": "",
        "Far-Sighted": "",
        "Presbyopic": "",
        "Monofocal": "",
        "Bifocal": "",
        "Progressive": "",
        "Optical": "",
        "Sun": "",
        "frontWidth": "142.00",
        "middleFrontalPoint": [0.0, 16.15, 0.0]
    },
    frontalBaseSize: {
        "id": "242",
        "MODELLO": "OV-807",
        "lensSize": "56.00",
        "calzata": "17.10",
        "DBL": "16.00",
        "NOSE": "17.00",
        "verticalLensSize": "50.60",
        "ED": "61.80",
        "HINGE_TO_HINGE": "134.000",
        "TemplePositionX": "-16.129",
        "TemplePositionY": "-66.941",
        "TemplePositionZ": "20.148",
        "AxisVectorX": "-14.671",
        "AxisVectorY": "-66.878",
        "AxisVectorZ": "19.799",
        "Material": "",
        "Shape": "",
        "Type": "",
        "Near-Sighted": "",
        "Far-Sighted": "",
        "Presbyopic": "",
        "Monofocal": "",
        "Bifocal": "",
        "Progressive": "",
        "Optical": "",
        "Sun": "",
        "frontWidth": "142.00"
    },
    print: false,
    shareConfiguration: {
        "data": {},
        "emailFrom": "",
        "emailTo": "",
    },
    emailSent: undefined,
    positionForPrint: false,
};
const configurator = {
    // export default new Vuex.Store<RootState>({
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
    modules: {
        cart,
        user,
        aste,
        frontali,
        lenti,
    },
};
export default configurator;
// export default new Vuex.Store<RootState>({
//   state,
//   mutations,
//   getters,
//   actions,
//   modules: {
//     cart,
//     user,
//     aste,
//     frontali,
//   },
// });
