import { MeshPhongMaterial } from "three";
/**
 * Code adapted from https://github.com/leannepepper/three-glitter-material
 *
 * This class is a custom material for a mesh that uses a shader to create a glitter effect.
 * It has been modified in order to work with our 3D models.
 *
 */
export class GlitterMaterial extends MeshPhongMaterial {
    constructor(customUniforms, parameters) {
        super();
        this.customUniforms = customUniforms;
        this.setValues(parameters);
        this.onBeforeCompile = shader => {
            shader.uniforms.uGlitterSize = this.customUniforms.uGlitterSize;
            shader.uniforms.uGlitterDensity = this.customUniforms.uGlitterDensity;
            shader.uniforms.uGlitterColor = this.customUniforms.uGlitterColor;
            shader.vertexShader = shader.vertexShader.replace('#include <common>', `
                #include <common>
                uniform float uGlitterSize;
                uniform float uGlitterDensity;
                uniform vec3 uGlitterColor;
                varying vec2 vUv;
                varying vec3 vPosition;
            `);
            shader.vertexShader = shader.vertexShader.replace('#include <begin_vertex>', `
                #include <begin_vertex>
                vUv = uv;
                `);
            //   Fragment Shader
            shader.fragmentShader = shader.fragmentShader.replace('#include <common>', `
                // Created by Inigo Quilez - iq/2014
                // Modified by Leanne Werner - 2022
                // Modified by Luca De Menego - 2022
  
                #include <common>
                varying vec2 vUv;
                varying vec3 vPosition;
                uniform float uGlitterSize;
                uniform float uGlitterDensity;
                uniform vec3 uGlitterColor;
      
  
                vec3 hash3( vec2 p ){
                    vec3 q = vec3( dot(p,vec2(127.1,311.7)),
                                dot(p,vec2(269.5,183.3)),
                                dot(p,vec2(419.2,371.9)) );
                    return fract(sin(q)*43758.5453);
                }
  
                // Voronoise implementation: https://iquilezles.org/articles/voronoise/voronoise.htm
                float iqnoise( in vec2 x, float u, float v, vec3 vnormal ) {
                
                    vec2 p = floor(x);
                    vec2 f = fract(x);
            
                    float k = 1.0+63.0*pow(1.0-v,4.0);
            
                    float va = 0.0;
                    float wt = 0.0;
                    for(int j = -2;  j <= 2; j++)
                        for(int i = -2; i <= 2; i++) {
                            vec2 g = vec2(float(i),float(j));
                            vec3 o = hash3(p + g) ;
                            vec2 r = g - f + o.xy;
                            float d = dot(r,r);
                            float ww = pow( 1.0-smoothstep(0.04,.100,sqrt(d)), k);
                            va += o.z*ww;
                            wt += ww ;
                        }
        
                    return va / wt;
                }
                `);
            shader.fragmentShader = shader.fragmentShader.replace('#include <normal_fragment_begin>', `
                #include <normal_fragment_begin>
                float f = iqnoise( (1.0 / uGlitterSize)*(vUv.xy)*vec2(2,2), .1, 1.0, normal );
                // Set the opacity of the glitters to 1.0
                vec4 glitterColor = vec4(uGlitterColor.r, uGlitterColor.g, uGlitterColor.b, 1.0);
                // Apply the glitterColor to the diffuseColor
                vec4 color = vec4(glitterColor.rgba);
                vec4 c = vec4(f);
                // Put the glitters based on a smoothstep, while maintaining an opacity of 0 for the rest of the material
                vec4 col = mix( vec4(0.0, 0.0, 0.0, 0.0), color.rgba, smoothstep(0.25 + uGlitterDensity, .25, c.x ) );
                // Override the diffuseColor with the newly calculated one
                diffuseColor.rgba = vec4(col).rgba;
            `);
        };
    }
}
