// import ajax from '../plugins/ajax_request'
export default {
    setFrontal(state, item) {
        state.loadingGeometry = true;
        state.frontale = item;
    },
    // setFrontali(state, f) {
    //   state.frontali = f;
    // },
    // setAste(state, a) {
    //   state.aste = a;
    // },
    setSize(state, size) {
        /*state.calibro = Math.ceil(size.size_eye);
        state.naso = Math.ceil(size.size_bridge);*/
        // let s = size.label.split("/");
        state.calibro = size.size_eye;
        state.naso = size.size_bridge;
    },
    setAsianFitting(state, af) {
        state.asianFitting = af;
    },
    setFinishing(state, f) {
        state.finitura = f;
    },
    setColore(state, c) {
        state.colore = c;
    },
    setLensesType(state, type) {
        state.glassType = type;
    },
    clearSize: (state) => {
        state.naso = "";
        state.calibro = "";
    },
    setTemplesColor(state, tc) {
        state.coloreAste = tc;
    },
    setTemplesMetalColor(state, c) {
        state.coloreMetalloAste = c;
    },
    unsetTemplesMetalColor(state) {
        state.coloreMetalloAste = null;
    },
    // setFinituraAste(state, f) {
    //   state.finituraAste = f;
    // },
    setTemplesFinishing(state, f) {
        state.finituraAste = f;
    },
    setTemplesMaterial(state, m) {
        state.templesMaterial = m;
    },
    setTemplesSize: (state, size) => {
        state.misuraAste = size;
    },
    setDefaultColors: (state, colors) => {
        state.defaultColors = colors;
    },
    setOverrideLinea: (state, linea) => {
        state.overrideLinea = linea;
    },
    setAbbellitore: (state, abbellitore) => {
        state.abbellitore = abbellitore;
    },
    setSunLensesModel: (state, lenses) => {
        state.lenteSole = lenses;
        if (lenses)
            state.glassType = 'S';
    },
    setOftLensesDxModel: (state, lenses) => {
        state.lenteVistaDx = lenses;
        if (lenses)
            state.glassType = 'V';
    },
    setOftLensesSxModel: (state, lenses) => {
        state.lenteVistaSx = lenses;
        if (lenses)
            state.glassType = 'V';
    },
    // TODO: inserire setter per lenti vista
    setRowNotes: (state, note) => {
        state.note = note;
    },
    /////// DEPRECATE - FUNZIONI SPOSTATE IN MODULO frontali
    // _setName: (state, name) => {
    //   state._nome = name;
    // },
    // _setColoreNome: (state, color) => {
    //   state._coloreNome = color;
    // },
    //////////////////////////////////////////
    startLoadingGeometry: (state) => {
        state.loadingGeometry = true;
    },
    stopLoadingGeometry: (state) => {
        state.loadingGeometry = false;
    },
    /*User mutations*/
    // setUser(state, user) {
    //   state.user = user;
    //   Vue.cookie.set("_identity", state.user.auth_key, 30);
    // },
    setShareConfiguration: (state, { data, emailFrom, emailTo }) => {
        // Ask to send an email with details about the current configuration
        state.shareConfiguration = { data, emailFrom, emailTo };
    },
    setPrint: (state, value) => {
        // Ask to print the current configuration
        state.print = value;
    },
    setPositionForPrint: (state, value) => {
        // Ask to prepare the position of the glasses for a print/share
        state.positionForPrint = value;
    },
    setEmailSent: (state, value) => {
        state.emailSent = value;
    },
    saveConfiguration: (state) => {
        if (state.frontali && state.aste) {
            const c = {
                frontale: state.frontale,
                calibro: state.calibro,
                naso: state.naso,
                asianFitting: state.asianFitting,
                colore: state.colore,
                finitura: state.finitura,
                coloreAste: state.coloreAste,
                coloreMetalloAste: state.coloreMetalloAste ? state.coloreMetalloAste : '',
                finituraAste: state.finituraAste,
                misuraAste: state.misuraAste,
                abbellitore: state.abbellitore ? state.abbellitore : '',
                nome: state.frontali.name,
                coloreNome: state.frontali.nameColor,
                fontNome: state.frontali.nameFont,
                note: state.note,
                nomeExtAstaSx: state.aste.extSxName,
                coloreNomeExtAstaSx: state.aste.extSxNameColor,
                fontNomeExtAstaSx: state.aste.extSxNameFont,
                nomeIntAstaSx: state.aste.intSxName,
                coloreNomeIntAstaSx: state.aste.intSxNameColor,
                fontNomeIntAstaSx: state.aste.intSxNameFont,
                nomeExtAstaDx: state.aste.extDxName,
                coloreNomeExtAstaDx: state.aste.extDxNameColor,
                fontNomeExtAstaDx: state.aste.extDxNameFont,
                nomeIntAstaDx: state.aste.intDxName,
                coloreNomeIntAstaDx: state.aste.intDxNameColor,
                fontNomeIntAstaDx: state.aste.intDxNameFont,
                lensSole: state.lenteSole,
                frontalInfo: state.frontalInfo,
                frontalBaseSize: state.frontalBaseSize,
            };
            state.configurations.push(c);
        }
    },
    restoreConfiguration: (state, id) => {
        if (state.frontali && state.aste) {
            const cfg = state.configurations[id];
            state.frontale = cfg.frontale; //modello frontale
            state.calibro = cfg.calibro;
            state.naso = cfg.naso;
            state.asianFitting = cfg.asianFitting;
            state.colore = cfg.colore;
            state.finitura = cfg.finitura;
            state.coloreAste = cfg.coloreAste;
            if (cfg.coloreAste)
                state.templesMaterial = cfg.coloreAste.METALTEMPLE == "1" ? 'MT' : 'PL';
            state.coloreMetalloAste = cfg.coloreMetalloAste;
            state.finituraAste = cfg.finituraAste;
            state.misuraAste = cfg.misuraAste;
            state.frontali.name = cfg.nome;
            state.frontali.nameColor = cfg.coloreNome;
            state.frontali.nameFont = cfg.fontNome;
            state.aste.extSxName = cfg.nomeExtAstaSx;
            state.aste.extSxNameColor = cfg.coloreNomeExtAstaSx;
            state.aste.extSxNameFont = cfg.fontNomeExtAstaSx;
            state.aste.intSxName = cfg.nomeIntAstaSx;
            state.aste.intSxNameColor = cfg.coloreNomeIntAstaSx;
            state.aste.intSxNameFont = cfg.fontNomeIntAstaSx;
            state.aste.extDxName = cfg.nomeExtAstaDx;
            state.aste.extDxNameColor = cfg.coloreNomeExtAstaDx;
            state.aste.extDxNameFont = cfg.fontNomeExtAstaDx;
            state.aste.intDxName = cfg.nomeIntAstaDx;
            state.aste.intDxNameColor = cfg.coloreNomeIntAstaDx;
            state.aste.intDxNameFont = cfg.fontNomeIntAstaDx;
            state.note = cfg.note;
            // // Lenti
            // if (state.lenti) {
            //   // state.lensesType = cfg.lensesType;
            //   state.lenteVistaDx = cfg.lensDx;
            //   state.lenteVistaSx = cfg.lensSx;
            //   // Specifiche lenti da vista
            //   state.lenti.sferaDx = cfg.sferaDx;
            //   state.lenti.sferaSx = cfg.sferaSx;
            //   state.lenti.cilindroDx = cfg.cilindroDx;
            //   state.lenti.cilindroSx = cfg.cilindroSx;
            //   state.lenti.asseDx = cfg.asseDx;
            //   state.lenti.asseSx = cfg.asseSx;
            //   state.lenti.semiDistDx = cfg.semiDistDx;
            //   state.lenti.semiDistSx = cfg.semiDistSx;
            //   state.lenti.altPupDx = cfg.altPupDx;
            //   state.lenti.altPupSx = cfg.altPupSx;
            //   state.lenti.addDx = cfg.addDx;
            //   state.lenti.addSx = cfg.addSx;
            // }
            state.lenteSole = cfg.lensSole;
            state.glassType = state.lenteSole ? "S" : "V";
        }
    },
    restoreJsonConfiguration: (state, cfg) => {
        if (state.frontali && state.aste) {
            state.frontale = cfg.frontale; //modello frontale
            state.calibro = cfg.calibro;
            state.naso = cfg.naso;
            state.abbellitore = cfg.abbellitore == "B" ? "B" : null;
            state.tipoAbbellitore = cfg.abbellitore == "B" ? "B" : "A";
            state.asianFitting = cfg.asianFitting;
            state.colore = cfg.colore;
            state.finitura = cfg.finitura;
            state.coloreAste = cfg.coloreAste;
            state.templesMaterial = cfg.coloreAste.METALTEMPLE == "1" ? 'MT' : 'PL';
            state.coloreMetalloAste = cfg.coloreMetalloAste;
            state.finituraAste = cfg.finituraAste;
            state.misuraAste = cfg.misuraAste;
            state.frontali.name = cfg.nome; //TODO: modulo frontali
            state.frontali.nameColor = cfg.coloreNome; //TODO: modulo frontali
            state.frontali.nameFont = cfg.fontNome;
            state.aste.extSxName = cfg.nomeExtAstaSx;
            state.aste.extSxNameColor = cfg.coloreNomeExtAstaSx;
            state.aste.extSxNameFont = cfg.fontNomeExtAstaSx;
            state.aste.intSxName = cfg.nomeIntAstaSx;
            state.aste.intSxNameColor = cfg.coloreNomeIntAstaSx;
            state.aste.intSxNameFont = cfg.fontNomeIntAstaSx;
            state.aste.extDxName = cfg.nomeExtAstaDx;
            state.aste.extDxNameColor = cfg.coloreNomeExtAstaDx;
            state.aste.extDxNameFont = cfg.fontNomeExtAstaDx;
            state.aste.intDxName = cfg.nomeIntAstaDx;
            state.aste.intDxNameColor = cfg.coloreNomeIntAstaDx;
            state.aste.intDxNameFont = cfg.fontNomeIntAstaDx;
            state.note = cfg.note;
            // Lenti
            if (state.lenti) {
                // state.lensesType = cfg.lensesType;
                state.lenteVistaDx = cfg.lensDx;
                state.lenteVistaSx = cfg.lensSx;
                // Specifiche lenti da vista
                state.lenti.sferaDx = cfg.sferaDx;
                state.lenti.sferaSx = cfg.sferaSx;
                state.lenti.cilindroDx = cfg.cilindroDx;
                state.lenti.cilindroSx = cfg.cilindroSx;
                state.lenti.asseDx = cfg.asseDx;
                state.lenti.asseSx = cfg.asseSx;
                state.lenti.semiDistDx = cfg.semiDistDx;
                state.lenti.semiDistSx = cfg.semiDistSx;
                state.lenti.altPupDx = cfg.altPupDx;
                state.lenti.altPupSx = cfg.altPupSx;
                state.lenti.addDx = cfg.addDx;
                state.lenti.addSx = cfg.addSx;
            }
            state.lenteSole = cfg.lensSole;
            state.glassType = state.lenteSole ? "S" : "V";
        }
    },
    removeConfiguration: (state, id) => {
        const fConfigurations = state.configurations.filter((cfg, index) => index != id);
        state.configurations = fConfigurations;
    },
    clearAllConfigurations(state) {
        state.configurations = [];
    },
    setConfirm(state) {
        state.confirm = !state.confirm;
    },
    setQuantity(state, q) {
        state.quantity = q;
    },
    setQRCode(state, newQRCode) {
        state.qrcode = newQRCode;
    },
    setFaceMesh(state, tc) {
        state.meshVertexTriangles = tc;
    },
    setFrontalePosition(state, tc) {
        //state.frontalePosition = tc;
    },
    /**
     * Setta il valore di state.userInfo a ui
     * @param {Object} state
     * @param {Object} ui
     */
    setUserInfo(state, ui) {
        state.userInfo = ui;
        console.log('setUserInfo');
    },
    setFrontaleMatrix(state, tc) {
        state.rotationMatrix = tc;
    },
    /**
     * Setta il valore di frontal base size
     * @param {Object} state
     */
    setFrontalInfo(state, fi) {
        state.frontalInfo = fi;
    },
    /**
     * Imposta l'oggetto state.frontalBaseSize
     * @param {Object} state
     * @param {Object} fbs
     */
    setFrontalBaseSize(state, fbs) {
        state.frontalBaseSize = fbs;
    },
    /**
     * Imposta tutti i dati da un ordine pre-costruito
     * @param {*} state
     * @param {*} ord
     */
    setFromOrder(state, ord) {
        // state.frontalBaseSize = ord.defaultSize;
        // state.frontalePosition = ord.frontalePosition;
        // state.loadingGeometry = true;
        // state.frontale = ord.frontale;
        // state.colore = ord.frontalColor;
        // state.misuraAste = ord.templesSize;
        // state.coloreAste = ord.templesColor;
    }
};
