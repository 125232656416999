import { Color, FrontSide, LinearFilter, MeshPhysicalMaterial, MultiplyBlending, RepeatWrapping, sRGBEncoding, Vector3 } from "three/src/Three";
import { lensesMaterialAttribute } from "./material_attributes";
import { front } from "./front";
import * as ENV from "../../libModules/constants";
import { textureLoader } from "./loaders";
let lensLeft;
let lensRight;
let lensMaterial;
let lensGeometry;
let lensTexture;
let desktopMaterial;
function loadLenses(frontale) {
    lensGeometry?.dispose();
    try {
        lensRight = frontale.scene.getObjectByName("LENTE_DX");
        if (!desktopMaterial) {
            desktopMaterial = lensRight.material.clone();
        }
        if (!lensMaterial) {
            lensMaterial = new MeshPhysicalMaterial();
        }
        lensRight.renderOrder = 2;
        lensLeft = lensRight.clone();
        lensLeft.scale.multiply(new Vector3(-1, 1, 1));
        // TODO: il materiale della lente dipende da che tipo di lente viene scelta, va parametrizzato per lenti da sole
        // Keep two different materials: one for desktop and one for the Flutter applications
        // Desktop base material
        desktopMaterial.depthWrite = lensesMaterialAttribute.depthWrite;
        desktopMaterial.roughness = lensesMaterialAttribute.roughness;
        desktopMaterial.color = lensesMaterialAttribute.color;
        desktopMaterial.metalness = lensesMaterialAttribute.metalness;
        desktopMaterial.blending = lensesMaterialAttribute.blending;
        desktopMaterial.transmission =
            lensesMaterialAttribute.transmission;
        desktopMaterial.transparent = false;
        desktopMaterial.thickness = lensesMaterialAttribute.thickness;
        desktopMaterial.side = FrontSide;
        desktopMaterial.envMapIntensity =
            lensesMaterialAttribute.envMapIntensity;
        desktopMaterial.ior = lensesMaterialAttribute.ior;
        desktopMaterial.clearcoat = lensesMaterialAttribute.clearCoat;
        desktopMaterial.clearcoatRoughness =
            lensesMaterialAttribute.clearcoatRoughness;
        desktopMaterial.side = lensesMaterialAttribute.side;
        if (desktopMaterial.map) {
            desktopMaterial.color = new Color("0xffffff");
            desktopMaterial.blending = MultiplyBlending;
        }
        // Application base material
        lensMaterial.depthWrite = lensesMaterialAttribute.depthWrite;
        lensMaterial.roughness = lensesMaterialAttribute.roughness;
        lensMaterial.color = lensesMaterialAttribute.color;
        lensMaterial.metalness = lensesMaterialAttribute.metalness;
        lensMaterial.blending = lensesMaterialAttribute.blending;
        lensMaterial.opacity = 0.1;
        lensMaterial.transparent = lensesMaterialAttribute.transparent;
        lensMaterial.thickness = lensesMaterialAttribute.thickness;
        lensMaterial.side = FrontSide;
        lensMaterial.envMapIntensity =
            lensesMaterialAttribute.envMapIntensity;
        lensMaterial.ior = lensesMaterialAttribute.ior;
        lensMaterial.clearcoat = lensesMaterialAttribute.clearCoat;
        lensMaterial.clearcoatRoughness =
            lensesMaterialAttribute.clearcoatRoughness;
        lensMaterial.side = lensesMaterialAttribute.side;
        // Use the app material if we are using an application, otherwise the desktop one
        if (ENV.isForApp)
            lensLeft.material = lensRight.material = lensMaterial;
        else
            lensLeft.material = lensRight.material = desktopMaterial;
        if (front) {
            front.add(lensLeft);
            front.add(lensRight);
        }
    }
    catch (error) {
        console.error("[Canvas3D] Nessun oggetto lente caricato nella scena");
    }
}
async function changeLensesMaterial(newVal) {
    if (newVal) {
        // Se siamo su APP vanno caricate le texture png che hanno la trasparenza
        lensTexture = await textureLoader.loadAsync(ENV.isForApp ? `${ENV.texturesUrl}${newVal.ItemCode}.png` : `${ENV.texturesUrl}${newVal.ItemCode}.jpg`
        // `/${newVal.ItemCode}.png`
        );
        lensTexture.magFilter = LinearFilter;
        lensTexture.anisotropy = 16;
        lensTexture.encoding = sRGBEncoding;
        lensTexture.wrapS = RepeatWrapping;
        lensTexture.wrapT = RepeatWrapping;
        lensTexture.flipY = false;
        lensTexture.repeat.set(1, 1);
        if (ENV.isForApp) {
            lensMaterial.map = lensTexture;
            lensMaterial.color = new Color("0xffffff");
            // Se siamo su APP utilizziamo semplicemente l'opacità per il materiale
            // Su app le cose non funzionano allo stesso modo a causa del fatto che lo sfondo è trasparente
            // La cosa più importante su app è non attivare il blending e non settare il colore a 0x000000
            lensMaterial.opacity = 0.75;
            lensLeft.material = lensRight.material = lensMaterial;
        }
        else {
            desktopMaterial.map = lensTexture;
            desktopMaterial.color = new Color("0xffffff");
            desktopMaterial.blending = MultiplyBlending;
            lensLeft.material = lensRight.material = desktopMaterial;
        }
        // lensMaterial.transparent = false;
    }
    else {
        if (ENV.isForApp) {
            lensMaterial.map = null;
            lensMaterial.color = lensesMaterialAttribute.color;
            lensMaterial.opacity = 0.1;
            lensLeft.material = lensRight.material = lensMaterial;
        }
        else {
            desktopMaterial.map = null;
            // desktopMaterial.emissiveMap = null;
            desktopMaterial.color = lensesMaterialAttribute.color;
            // desktopMaterial.transparent = true;
            desktopMaterial.blending = lensesMaterialAttribute.blending;
        }
    }
    desktopMaterial.needsUpdate = true;
    lensMaterial.needsUpdate = true;
}
export { lensLeft, lensRight, lensMaterial, lensGeometry, lensTexture, loadLenses, changeLensesMaterial };
