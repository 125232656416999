import * as ENV from '../../libModules/constants';
import ajax from '../../plugins/ajax_request';
class Lenti {
    constructor() {
        this.items = [];
        this.sferaDx = 0;
        this.sferaSx = 0;
        this.cilindroDx = 0;
        this.cilindroSx = 0;
        this.asseDx = 0;
        this.asseSx = 0;
        this.semiDistDx = 0;
        this.semiDistSx = 0;
        this.altPupDx = 0;
        this.altPupSx = 0;
        this.addDx = 0;
        this.addSx = 0;
    }
}
const mutations = {
    setSferaDx(state, sferaDx) {
        state.sferaDx = sferaDx;
    },
    setSferaSx(state, sferaSx) {
        state.sferaSx = sferaSx;
    },
    setCilindroDx(state, cilindroDx) {
        state.cilindroDx = cilindroDx;
    },
    setCilindroSx(state, cilindroSx) {
        state.cilindroSx = cilindroSx;
    },
    setAsseDx(state, asseDx) {
        state.asseDx = asseDx;
    },
    setAsseSx(state, asseSx) {
        state.asseSx = asseSx;
    },
    setSemiDistDx(state, semiDistDx) {
        state.semiDistDx = semiDistDx;
    },
    setSemiDistSx(state, semiDistSx) {
        state.semiDistSx = semiDistSx;
    },
    setAltPupDx(state, altPupDx) {
        state.altPupDx = altPupDx;
    },
    setAltPupSx(state, altPupSx) {
        state.altPupSx = altPupSx;
    },
    setAddDx(state, addDx) {
        state.addDx = addDx;
    },
    setAddSx(state, addSx) {
        state.addSx = addSx;
    },
    resetCfgLenteVistaDx(state) {
        state.sferaDx = 0;
        state.cilindroDx = 0;
        state.asseDx = 0;
        state.semiDistDx = 0;
        state.altPupDx = 0;
        state.addDx = 0;
    },
    resetCfgLenteVistaSx(state) {
        state.sferaSx = 0;
        state.cilindroSx = 0;
        state.asseSx = 0;
        state.semiDistSx = 0;
        state.altPupSx = 0;
        state.addSx = 0;
    },
};
const getters = {
    haveLenses(state, getters, rootState, rootGetters) {
        return state.items.length > 0;
    },
    checkErrors(state, getters, rootState, rootGetters) {
        // TODO: gestire errori lenti da vista
        const errors = [];
        if (rootState.configurator.frontale?.U_TIPO_VSP == 'S' && rootState.configurator.lenteSole == null) {
            // in questo caso è obbligatorio scegliere una lente
            errors.push({
                label: 'sunLenses',
                message: 'Per modelli di tipo sole è obbligatorio selezionare una lente'
            });
        }
        return errors;
    },
    sunLenses(state, getters, rootState, rootGetters) {
        return state.items.filter((l) => {
            return l.U_THE_CATMERC == 'LEN';
        });
    },
    oftLenses(state, getters, rootState, rootGetters) {
        return state.items.filter((l) => {
            return l.U_THE_CATMERC == 'LENOF';
        });
    }
};
const actions = {
    async getLenses({ state, commit }) {
        try {
            const l = await (await ajax.getLenses()).data;
            state.items = l;
            l.forEach((lens) => {
                lens.imageUrl = `${ENV.texturesUrl}${lens.ItemCode}.jpg`;
            });
        }
        catch (error) {
            console.warn('[Canvas3D] Nessuna lente abilitata!', error);
        }
    },
    setAnItem({ state, commit }, defaultSunLenses) {
        try {
            // if (!defaultSunLenses) {
            //   const rnd = _.random(state.items.length);
            //   commit("configurator/setSunLensesModel", state.items[rnd], { root: true });
            // } else {
            //   const temp = state.items.filter((i: Lenses) => {
            //     return i.ItemCode == defaultSunLenses;
            //   })
            //   commit("configurator/setSunLensesModel", temp[0], { root: true });
            // }
            // commit("configurator/setLensesType", "S", { root: true });
            if (defaultSunLenses) {
                const temp = state.items.filter((i) => {
                    return i.ItemCode == defaultSunLenses;
                });
                commit("configurator/setLensesType", "S", { root: true });
                commit("configurator/setSunLensesModel", temp[0], { root: true });
            }
        }
        catch (error) {
            console.warn('[Canvas3D] Errore set lente da sole di default', error);
        }
    }
};
export const lenti = {
    namespaced: true,
    state: new Lenti(),
    mutations,
    getters,
    actions
};
