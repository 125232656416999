import { TextureLoader } from "three/src/Three";
import * as ENV from "../../libModules/constants";
let textureThickness;
/**
 * Inizializza tutte le texture
 *
 * Funzione chiamata durante l'inizializzazione del Canvas, dopo aver caricato le variabili in ENV
 */
function initTextures() {
    textureThickness = new TextureLoader().load(`${ENV.modelsUrl}${ENV.configIdentifier}/attenuation_map_16.png`);
}
export { initTextures, textureThickness };
