/* eslint-disable no-unused-vars */
import ajax from "../../plugins/ajax_request";
import { EventBus } from '../../plugins/EventBus';
import * as ENV from '../../libModules/constants';
import { isEmpty, random } from "lodash";
// const state = () => ({ name: '', nameColor: '', items: [], itemsSizes: [], colors: [] });
class Frontali {
    constructor() {
        this.name = '';
        this.nameColor = '';
        this.nameFont = 'Batang';
        this.items = [];
        this.itemsSizes = [];
        this.colors = [];
    }
}
const mutations = {
    setName: (state, name) => {
        state.name = name;
    },
    setNameColor: (state, color) => {
        state.nameColor = color;
    },
    setNameFont: (state, font) => {
        state.nameFont = font;
    }
};
const getters = {
    // checkErrors(state: FrontalState, rootState: MainState) {
    checkErrors(state, getters, rootState, rootGetters) {
        const errors = [];
        if (isEmpty(rootState.configurator.frontale)) {
            errors.push({ label: "model", message: "Il frontale è obbligatorio" });
        }
        if (rootState.configurator.calibro == "" || rootState.configurator.naso == "") {
            errors.push({ label: "size", message: "La misura è obbligatoria" });
        }
        if (isEmpty(rootState.configurator.colore)) {
            errors.push({ label: "color", message: "Il colore è obbligatorio" });
        }
        if (isEmpty(rootState.configurator.finitura)) {
            errors.push({
                label: "finishing",
                message: "La finitura è obbligatoria",
            });
        }
        return errors;
    },
};
const actions = {
    async getItems({ state, commit }) {
        try {
            const res = await ajax.getItems(null);
            state.items = res.data;
            state.items.forEach((m) => {
                // TODO: inserisco a mano media.thema-optical.com in quanto queste immagini non sono ancora disponibili su cdn
                // poi dovremo usare ENV
                m.imageUrl = `https://media.thema-optical.com/3d_models/${m.U_THE_MODELLO.replace('SC', 'C')}.png`;
            });
        }
        catch (error) {
            console.error('Error on get frontals', error);
        }
    },
    async setAnItem({ state, commit }, defaultModel) {
        if (!defaultModel) {
            const rnd = random(state.items.length);
            commit("configurator/setFrontal", state.items[rnd], { root: true });
        }
        else {
            const temp = state.items.filter((i) => {
                return i.U_THE_MODELLO == defaultModel;
            });
            commit("configurator/setFrontal", temp[0], { root: true });
        }
        EventBus.$emit('frontals-loaded');
    },
    async getAllModelsSizes({ state }) {
        try {
            const res = await ajax.getAllModelsSizes();
            state.itemsSizes = res.data;
            EventBus.$emit('model-sizes-loaded');
        }
        catch (error) {
            console.error('Error on get model sizes', error);
            EventBus.$emit('model-sizes-load-error');
        }
    },
    async getColors({ state }) {
        try {
            const res = await ajax.getColors();
            state.colors = res.data;
            state.colors.forEach((c) => {
                c.imageUrl = `${ENV.texturesUrl}${c.U_THE_COLORE}.jpg`;
                c.backImageUrl = `${ENV.texturesUrl}${c.U_THE_COLORE}_b.jpg`;
            });
            EventBus.$emit('colors-loaded');
        }
        catch (error) {
            console.error('Error on get frontals colors', error);
            EventBus.$emit('colors-load-error');
        }
    },
    async setColor({ state, rootState, dispatch }, color) {
        if (state.colors.length == 0) {
            await dispatch('getColors');
        }
        if (color) {
            state.colors.forEach(c => {
                if (c.U_THE_COLORE == color) {
                    rootState.configurator.colore = c;
                    return;
                }
            });
        }
        else {
            const n = random(0, state.colors.length - 1, false);
            rootState.configurator.colore = state.colors[n];
        }
    }
};
export const frontali = {
    namespaced: true,
    state: new Frontali(),
    getters,
    mutations,
    actions,
};
