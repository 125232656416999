import { MeshPhongMaterial, MeshPhysicalMaterial, MeshStandardMaterial, ShaderMaterial } from "three/src/Three";
let frontMaterial;
let silverMaterial;
let gunMaterial;
let goldMaterial;
// let abOcclusion: Texture;
let abbellitoreGunMaterial;
let projectionMaterial;
/**
 * Inizializza tutti i materiali
 *
 * Funzione chiamata durante l'inizializzazione del Canvas, dopo aver caricato le variabili in ENV
 */
function initMaterials() {
    frontMaterial = [
        new MeshPhysicalMaterial(),
        new MeshStandardMaterial(),
        new MeshPhongMaterial(),
    ];
    silverMaterial = new MeshPhysicalMaterial({
        name: "SilverMaterial",
        // color: 0xff0000,
        color: 0xbababa,
        roughness: 0.1,
        metalness: 0.95,
    });
    gunMaterial = new MeshPhysicalMaterial({
        name: "GunMaterial",
        color: 0x4f4f4f,
        roughness: 0.3,
        metalness: 0.95,
    });
    goldMaterial = new MeshPhysicalMaterial({
        name: "GoldMateria",
        color: 0xffd285,
        roughness: 0.3,
        metalness: 0.95,
    });
    // abOcclusion = textureLoader.load(
    //   `${ENV.modelsUrl}ambient/abbellitore_occlusion.png`
    // );
    abbellitoreGunMaterial = new MeshPhysicalMaterial({
        color: 0x383838,
        roughness: 0.1,
        metalness: 0.6,
    });
    projectionMaterial = new ShaderMaterial();
}
export { initMaterials, frontMaterial, silverMaterial, gunMaterial, goldMaterial, abbellitoreGunMaterial, projectionMaterial };
