/**@module store/user */
import ajax from "../../plugins/ajax_request";
import { isEmpty } from "lodash";
import Vue from "vue";
// import router from "../../router";
import axios from "axios";
const state = {
    user: null,
    orderHistory: null,
    orderStatus: [],
    orderDetails: [],
    historyLoading: true,
    percentage: 0,
};
// class UserState {
//   user= {};
//   orderHistory= [];
//   orderStatus= [];
//   orderDetails= [];
//   historyLoading= true;
//   percentage= 0;
// }
const getters = {
    isGuest: (state, getters, rootState, rootGetters) => {
        return isEmpty(state.user);
    },
};
const mutations = {
    setLoginCookie(state) {
        if (state.user) {
            ajax.setAuthHeader(state.user.auth_key);
            Vue.$cookies.set("_identity", state.user.auth_key, "30d");
        }
    },
    removeLoginCookie() {
        Vue.$cookies.remove("_identity");
        ajax.deleteAuthHeader();
    },
};
const actions = {
    async login({ state, commit, dispatch }, { username, password }) {
        const response = await ajax.login(username, password);
        if (response.status >= 200 &&
            response.status < 300 &&
            !response.data.error) {
            state.user = response.data;
            commit("setLoginCookie");
            dispatch("getUserCart");
        }
        return response;
    },
    // eslint-disable-next-line no-unused-vars
    async getUserData({ state, dispatch }) {
        if (Vue.$cookies.isKey("_identity")) {
            const identity = await Vue.$cookies.get("_identity");
            if (identity.length == 32)
                ajax.setAuthHeader(identity);
            else {
                const c = identity.indexOf('[');
                const t = identity.indexOf(']');
                const p = identity.substring(c + 1, t).replace("\"", "").replace("\"", "");
                const split_string = p.split(",");
                if (split_string[1].length == 32) {
                    ajax.setAuthHeader(split_string[1]);
                }
                else {
                    console.error("[Canvas3D] identity cookie errato.");
                }
            }
            const res = await ajax.getUserData();
            if (res.data) {
                state.user = res.data;
                dispatch("getUserCart");
                // commit('setLoginCookie');
            }
        }
    },
    getUserCart: ({ rootState }) => {
        console.warn('[user.ts ... ]', rootState);
        if (!isEmpty(rootState.configurator.cart)) {
            ajax.getUserCart().then((response) => {
                if (rootState.configurator.cart) {
                    rootState.configurator.cart.cartRows = response.data;
                }
            });
        }
        else {
            console.warn("[Configurator] - Il modulo carrello non è disponibile");
        }
    },
    async getUserHistory({ state }) {
        if (state.user) {
            const history = await ajax.getUserHistory();
            // console.log("history from store", history.data);
            // for (let i = 0; i < history.data.length; i++) {
            //   const element = history.data[i];
            //   const status = await ajax.getOrdersStatus(history.data[i].ID);
            //   const details = await ajax.getOrderDetails(history.data[i].ID);
            //   element.status = [];
            //   element.status = status.data;
            //   element.details = [];
            //   element.details = details.data;
            //   let p = round(100 / history.data.length, 0);
            //   state.percentage = state.percentage + p;
            // }
            if (history.data) {
                state.orderHistory = history.data;
                state.historyLoading = false;
            }
        }
    },
    /**
     *
     * Recupera il country code dell'utente, se loggato viene recuperato dal database
     * altrimenti viene rilevato in base all'IP di connessione
     * @returns {string} country code dell'utente
     */
    async getCountry() {
        const a = axios.create();
        const country = await ajax.getCountryCode();
        delete a.defaults.headers.common["site-id"];
        delete a.defaults.headers.common["line-id"];
        delete a.defaults.headers.common["Authorization"];
        // country = await ajax.getUserCountryInfo();
        return country.data[0];
    },
    /**
     * Effettua il logout cancellando dai cookies l'auth_key e inizializza
     * store.user ad un oggetto vuoto
     * @param {Object} store global state
     * @param {Function} function for commit mutations
     */
    logout: async ({ state, commit }) => {
        const res = await ajax.logout();
        if (res.data == 1) {
            state.user = null;
            commit("removeLoginCookie");
            // completare con eventuali azioni che riportano alla pagina di login
            // router.push("/");
        }
    },
};
export const user = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
