import { __decorate } from "tslib";
import { mapGetters, mapMutations, mapState } from "vuex";
import { Component, Prop, Vue } from "vue-property-decorator";
import qrcode from "qrcode";
import ajax_request from "@/plugins/ajax_request";
import { render } from "./canvas/render";
import { camera, scene } from "./canvas/scene";
let PrintComponent = class PrintComponent extends Vue {
    constructor() {
        super(...arguments);
        this.linea = "";
        this.canvasImageURL = "";
        this.urlSplit = "";
        this.isMultistrato = false;
        this.id_for_url = "";
        this.windowRef = null;
    }
    get finitura() {
        return this.$store.state.configurator.finitura;
    }
    get finituraAste() {
        return this.$store.state.configurator.finituraAste;
    }
    async created() {
        // Create a QRCode out of the active configuration
        let json = JSON.stringify(this.getActiveConfiguration);
        let res = await ajax_request.setCustomConfigurations(json);
        this.id_for_url = res.data;
        this.url = window.location.href;
        let qr = await this.makeQRCode(res.data, this.url);
        this.setQRCode(qr);
        // Re-render the scene, since it changed due to the update on camera position
        render.render(scene, camera);
        // Find the current line to choose the correct title and image in the template
        this.linea = Vue.prototype.$settings.VUE_APP_CONFIG_IDENTIFIER ?? "";
        this.isMultistrato =
            this.getActiveConfiguration?.colore?.Category == "MULTISTRATO";
        this.urlSplit = this.url;
        let idc1 = this.url.split("/");
        if (parseInt(idc1[idc1.length - 1]) > 0) {
            idc1.pop();
            this.urlSplit = `${idc1.join("/")}/`;
        }
        if (!this.urlSplit.endsWith("/"))
            this.urlSplit = `${this.urlSplit}/`;
        // get canvas image and crop it in the center
        let canvas = document.getElementsByTagName("canvas")[0];
        let imgCanvas = document.getElementById("img-canvas");
        imgCanvas.width = canvas.width;
        imgCanvas.height = canvas.height / 2;
        // Crop canvas in the center
        let ctx = imgCanvas.getContext("2d");
        let image = document.createElement("img");
        image.src = canvas.toDataURL("image/png");
        image.onload = () => {
            let width = image.width;
            let height = image.height;
            ctx.drawImage(image, 0, Math.max(0, height / 4), width, height / 1.45, 0, 0, imgCanvas.width, height / 1.45);
            this.canvasImageURL = imgCanvas.toDataURL("image/png");
            this.$emit("update:printed", true);
        };
    }
    async makeQRCode(id, url = window.location.href) {
        let id1 = url.split("/");
        if (parseInt(id1[id1.length - 1]) > 0) {
            id1.pop();
            return qrcode.toDataURL(`${id1.join("/")}/${id}`);
        }
        else {
            if (url[url.length - 1] != "/")
                url = `${url}/`;
            return qrcode.toDataURL(url + id);
        }
    }
};
__decorate([
    Prop()
], PrintComponent.prototype, "printed", void 0);
PrintComponent = __decorate([
    Component({
        name: "PrintComponent",
        computed: {
            ...mapGetters("configurator", {
                frontModel: "frontModel",
                frontColor: "frontColor",
                frontSize: "frontSize",
                templesColor: "templesColor",
                getActiveConfiguration: "getActiveConfiguration",
            }),
            ...mapState("configurator", {
                coloreMetalloAste: "coloreMetalloAste",
                // finitura: "finitura",
                // finituraAste: "finituraAste",
                qrcode: "qrcode",
            }),
        },
        methods: {
            ...mapMutations("configurator", {
                setQRCode: "setQRCode",
            }),
        },
    })
], PrintComponent);
export default PrintComponent;
