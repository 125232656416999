const vertexShader = `
    precision highp float;
    varying vec4 vTexCoords;
    varying vec4 vWorldPosition;
    void main() {
        vWorldPosition = modelMatrix * vec4(position, 1.0);
        vTexCoords = projectionMatrix * viewMatrix * vWorldPosition;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`;
const fragmentShader = `
    precision highp float;
    uniform sampler2D texture1;
    varying vec4 vTexCoords;
    void main() {
        vec2 uv = (vTexCoords.xy / vTexCoords.w) * 0.5 + 0.5;
        gl_FragColor = texture2D( texture1, uv);
    }`;
export { vertexShader, fragmentShader };
