import { gltfLoader } from "./loaders";
import * as ENV from "../../libModules/constants";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Scene, PerspectiveCamera, Mesh, Color, Fog, Vector3, DirectionalLight, BoxGeometry, MeshBasicMaterial } from "three/src/Three";
import { gpuTier } from "./render";
const scene = new Scene();
/** DEPRECATA **/
let width = 0; // per ottimizzazione di animate sono trasferite su Canvas
/** DEPRECATA **/
let height = 0; // per ottimizzazione di animate sono trasferite su Canvas
let initialAspect = 1;
const colorLight = "0xffffff";
let camera;
let controls;
let cmesh = new Mesh();
let cmesh_1 = new Mesh();
/** DEPRECATA **
 * Per motivi di performance la variabile initialAspect è stata spostata su Canvas
 * ed è gestita direttamente nell'animate
 */
function checkCanvasSize() {
    const container = document.getElementById("canvas-container");
    width = ENV.isForApp ? window.innerWidth : (container ? container.offsetWidth : 0);
    height = ENV.isForApp ? window.innerHeight : (container ? container.offsetHeight : 0);
    initialAspect = width / height;
    // Set the width and height of the html container
    if (container && ENV.isForApp) {
        // Remove margin from HTML body
        document.body.style.margin = "0";
        container.style.width = `${width}px`;
        container.style.height = `${height}px`;
    }
}
async function setupScene(shouldLoadScene) {
    if (shouldLoadScene) {
        try {
            // const sceneConfig = await gltfLoader.loadAsync("/SCENA_MOBILE.glb");
            const sceneConfig = await gltfLoader.loadAsync(`${ENV.modelsUrl}${ENV.configIdentifier}/scena.glb`);
            sceneConfig.scene.traverse((o) => {
                if (o.isMesh) {
                    o.castShadow = true;
                    o.receiveShadow = true;
                }
            });
            sceneConfig.scene.castShadow = true;
            sceneConfig.scene.receiveShadow = true;
            scene.add(sceneConfig.scene);
            scene.background = new Color(0xdbdbdb);
        }
        catch (e) {
            console.warn("Errore caricamento scena", e);
            scene.background = new Color(0xffffff);
        }
        const fog = new Fog(0xdbdbdb, 500, 900);
        scene.fog = fog;
    }
    else {
        scene.background = ENV.isForApp ? null : new Color(0xffffff);
    }
}
async function setCamera(w, h, shouldLoadScene) {
    if (ENV.isForApp) {
        camera = new PerspectiveCamera(45, w / h, 1, 1000);
        camera.position.x = 0;
        camera.position.y = 0;
        camera.position.z = 250;
    }
    else {
        const dampingFactorRank = [0, 0, 0.2, 0.08];
        camera = new PerspectiveCamera(30, w / h, 10, 2000);
        if (process.env.VUE_APP_CONFIG_IDENTIFIER == 'OSIX-INC') {
            camera.position.x = -200.39674912045976;
            camera.position.y = 100.14617656006095;
            camera.position.z = 350.93022525576237;
            camera.updateProjectionMatrix();
        }
        else {
            camera.position.x = -144.39674912045976;
            camera.position.y = 76.14617656006095;
            camera.position.z = 281.93022525576237;
            camera.updateProjectionMatrix();
        }
        controls = new OrbitControls(camera, document.getElementById("canvas-container"));
        // Disabilita gli OrbitControls se il dispositivo ha gpu tier 0
        controls.enabled = gpuTier.tier != 0;
        // Nota: su dispositivi mobile quando si fa lo zoom è facile spostare la camera inavvertitamente
        // per questo forse sarebbe meglio disabilitare controls.enablePan
        controls.enablePan = false;
        controls.enableZoom = true;
        controls.enableDamping = gpuTier.tier > 1;
        controls.dampingFactor = dampingFactorRank[gpuTier.tier];
        if (process.env.VUE_APP_CONFIG_IDENTIFIER == 'OSIX-INC') {
            controls.maxDistance = w / h > 2.6 ? 350 : 500;
            controls.minDistance = w / h > 2.6 ? 200 : 250;
        }
        else {
            controls.maxDistance = w / h > 2.6 ? 270 : 500;
            controls.minDistance = w / h > 2.6 ? 200 : 250;
        }
        controls.maxPolarAngle = shouldLoadScene ? 1.75 : 2;
        controls.minPolarAngle = Math.PI / 4;
        controls.target = new Vector3(0, 20, -75);
    }
    return camera;
}
async function setLights() {
    // Inizio gestione luci
    const directionalLight1 = new DirectionalLight(colorLight, 1);
    directionalLight1.position.set(-150, 130, 250);
    directionalLight1.shadow.bias = 0.01;
    directionalLight1.castShadow = true;
    directionalLight1.shadow.camera.left = -250;
    directionalLight1.shadow.camera.right = 250;
    directionalLight1.shadow.camera.top = 250;
    directionalLight1.shadow.camera.bottom = -250;
    directionalLight1.shadow.camera.far = 800;
    scene.add(directionalLight1);
    // const hd1 = new DirectionalLightHelper(directionalLight1, 100);
    // scene.add(hd1);
    const directionalLight4 = new DirectionalLight(colorLight, 0.1);
    directionalLight4.position.set(150, 130, -250);
    directionalLight4.shadow.bias = 0.01;
    directionalLight4.target.position.set(20, 0, 20);
    directionalLight4.castShadow = false;
    scene.add(directionalLight4);
    scene.add(directionalLight4.target);
    // const d4h = new DirectionalLightHelper(directionalLight4);
    // scene.add(d4h);
    directionalLight4.target.updateMatrixWorld();
    // d4h.update();
}
function setPianiMesh() {
    //Creo una nuova geometria che rappresenterà un piano che dovrà
    //essere attaccato alla mesh e dare l'impressione che i terminali dell'occhiale
    //stiano coperti dalle orecchie
    const Cgeometry = new BoxGeometry(100, 100, 0);
    const Cmaterial = new MeshBasicMaterial({
        color: 0x0000ff,
        opacity: 0,
        colorWrite: false,
        transparent: false,
    });
    cmesh = new Mesh(Cgeometry, Cmaterial);
    const Cgeometry1 = new BoxGeometry(150, 100, 100);
    const Cmaterial1 = new MeshBasicMaterial({
        color: 0x0000ff,
        opacity: 0,
        colorWrite: false,
        transparent: false,
    });
    cmesh_1 = new Mesh(Cgeometry1, Cmaterial1);
    // this.Cmesh_1.position.y += 25
    cmesh_1.rotateX(-0.523599);
}
export { scene, camera, controls, width, height, initialAspect, cmesh, cmesh_1, checkCanvasSize, setupScene, setCamera, setLights, setPianiMesh };
