/**
 * File exporting all the most important constants of the application
 *
 * In order to make it work, you first need to call setupConstants, passing to it the current settings
 */
let modelsUrl = ``;
let texturesUrl = ``;
let configIdentifier = ``;
let configSite = ``;
let sendgridShareMailTemplateID = '';
let sendgridAPIKey = '';
let isForApp = false;
let loadScene = true;
let enableStats = false;
let gtagId = '';
/**
 * la variabile serve per i siti che funzionano su più linee e, per ogni linea,
 * deve essere caricata un'asta diversa
 */
let multiLineTemples = false;
/**
 * Setup the module with the correct configuration
 * @param settings Object containing all the current settings
 */
function setupConstants(settings) {
    modelsUrl = `${settings.VUE_APP_MODEL_URL}`;
    texturesUrl = `${settings.VUE_APP_COLORS_URL}`;
    configIdentifier = `${settings.VUE_APP_CONFIG_IDENTIFIER}`;
    configSite = `${settings.VUE_APP_CONFIG_SITE}`;
    sendgridShareMailTemplateID = `${settings.VUE_APP_SENDGRID_SHARE_MAIL_TEMPLATE_ID}`;
    sendgridAPIKey = `${settings.VUE_APP_SENDGRID_API_KEY}`;
    isForApp = settings.VUE_APP_FOR_APP === 'Y';
    multiLineTemples = settings.VUE_APP_MULTILINETEMPLES == 'true';
    loadScene = settings.VUE_APP_LOAD_SCENE == 'false' ? false : true;
    enableStats = settings.VUE_APP_ENABLE_STATS == 'true';
    gtagId = `${settings.VUE_APP_GTAG_ID ?? ''}`;
}
export { modelsUrl, texturesUrl, configIdentifier, configSite, sendgridShareMailTemplateID, sendgridAPIKey, isForApp, multiLineTemples, enableStats, loadScene, gtagId, setupConstants, };
