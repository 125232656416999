// import Vue from 'vue'
import Canvas from './components/Canvas.vue';
import store from './store';
import ajax from './plugins/ajax_request';
import axios from 'axios';
import { setupConstants } from './libModules/constants';
class ConfiguratorPlugin {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static install(vue, options) {
        // instance.component('Canvas3d', Canvas);
        // vue.mixin({ created: () => vue.component('Canvas3d', Canvas) })
        if (options?.store) {
            options.store.registerModule('configurator', store);
        }
        else
            throw new Error("Per utilizzare il plugin deve essere presente uno store");
        // Controlla che sia stata fornita una configurazione per configurator-base-app
        if (options?.settings) {
            // Controlla che siano stati forniti tutti i campi obbligatori
            if (!options.settings.VUE_APP_REST_URL) {
                throw new Error("Per utilizzare il plugin è necessario definire VUE_APP_REST_URL");
            }
            if (!options.settings.VUE_APP_CONFIG_IDENTIFIER) {
                throw new Error("Per utilizzare il plugin è necessario definire VUE_APP_CONFIG_IDENTIFIER");
            }
            if (!options.settings.VUE_APP_CONFIG_ESCLUSIVA) {
                // throw new Error("Per utilizzare il plugin è necessario definire VUE_APP_CONFIG_ESCLUSIVA");
            }
            if (!options.settings.VUE_APP_COLORS_URL) {
                throw new Error("Per utilizzare il plugin è necessario definire VUE_APP_COLORS_URL");
            }
            if (!options.settings.VUE_APP_MODEL_URL) {
                throw new Error("Per utilizzare il plugin è necessario definire VUE_APP_MODEL_URL");
            }
        }
        else
            throw new Error("Per utilizzare il plugin deve essere presente un file di configurazione");
        // Salva la configurazione in una variabile globale dell'istanza Vue
        vue.prototype.$settings = options?.settings;
        vue.prototype.$settings.VUE_APP_FOR_APP = "N";
        setupConstants(vue.prototype.$settings);
        // Configura axios con i nuovi valori forniti dall'utente
        axios.defaults.baseURL = options.settings.VUE_APP_REST_URL;
        axios.defaults.headers.common["line-id"] = options.settings.VUE_APP_CONFIG_IDENTIFIER;
        axios.defaults.headers.common["site-id"] = options.settings.VUE_APP_CONFIG_IDENTIFIER;
        ajax.esclusiva = options.settings.VUE_APP_CONFIG_ESCLUSIVA || null;
        if (options.settings.VUE_APP_CONFIG_DATABASE) {
            axios.defaults.headers.common.environment = options.settings.VUE_APP_CONFIG_DATABASE;
        }
        vue.prototype.$rest = ajax;
        vue.prototype.$canvas3d = Canvas;
    }
}
export default ConfiguratorPlugin;
