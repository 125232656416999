import { CineonToneMapping, CubeTextureLoader, LightProbe, LinearFilter, Mesh, MeshBasicMaterial, PCFSoftShadowMap, PlaneBufferGeometry, RGBFormat, sRGBEncoding, WebGLMultisampleRenderTarget, WebGLRenderer } from "three/src/Three";
import { scene, camera } from "./scene";
import { projectionMaterial } from "./materials";
import { vertexShader, fragmentShader } from "./shaders";
// import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
// import { CopyShader } from "three/examples/jsm/shaders/CopyShader.js";
// import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { LightProbeGenerator } from "three/examples/jsm/lights/LightProbeGenerator";
import * as ENV from "../../libModules/constants";
import { getGPUTier } from "detect-gpu";
const render = new WebGLRenderer({
    alpha: true,
    antialias: true,
    powerPreference: 'high-performance'
});
let isWebgl2Supported;
let rTarget;
let composer2;
let planeRenderTarget;
let gpuTier = undefined;
let pixelRatioRank;
async function checkGpu() {
    gpuTier = await getGPUTier({ glContext: render.getContext() });
    pixelRatioRank = [1, 2, 1.09, 1];
}
async function setupRender(width, height) {
    const canvasTest = document.createElement('canvas');
    const context = canvasTest.getContext('webgl2');
    if (context) {
        isWebgl2Supported = true;
        // Dispose the context
        context.getExtension('WEBGL_lose_context')?.loseContext();
    }
    else {
        isWebgl2Supported = false;
    }
    if (!gpuTier) {
        await checkGpu();
    }
    if (isWebgl2Supported) {
        rTarget = new WebGLMultisampleRenderTarget(width, height, {
            magFilter: LinearFilter,
            minFilter: LinearFilter,
            encoding: sRGBEncoding,
            format: RGBFormat,
        });
        rTarget.samples = 1;
        projectionMaterial.uniforms = {
            texture1: { value: rTarget.texture },
        };
        projectionMaterial.vertexShader = vertexShader;
        projectionMaterial.fragmentShader = fragmentShader;
    }
    // composer2 = new EffectComposer(render, rTarget);
    // composer2.setPixelRatio(window.devicePixelRatio); // ensure pixel ratio is always 1 for performance reasons
    // const renderPass = new RenderPass(scene, camera);
    // const copyPass = new ShaderPass(CopyShader);
    // composer2.addPass(renderPass);
    // composer2.addPass(copyPass);
    const visibleHeightAtZDepth = (depth, camera) => {
        // compensate for cameras not positioned at z=0
        const cameraOffset = camera.position.z;
        if (depth < cameraOffset)
            depth -= cameraOffset;
        else
            depth += cameraOffset;
        // vertical fov in radians
        const vFOV = (camera.fov * Math.PI) / 180;
        // Math.abs to ensure the result is always positive
        return 2 * Math.tan(vFOV / 2) * Math.abs(depth);
    };
    const h = visibleHeightAtZDepth(0, camera);
    const gPlaneRenderTarget = new PlaneBufferGeometry(h * camera.aspect, h);
    planeRenderTarget = new Mesh(gPlaneRenderTarget, new MeshBasicMaterial({
        map: isWebgl2Supported ? rTarget.texture : null,
        toneMapped: false,
    }));
    planeRenderTarget.castShadow = planeRenderTarget.receiveShadow =
        false;
    planeRenderTarget.visible = false;
    scene.add(planeRenderTarget);
    planeRenderTarget.position.z = -450;
    planeRenderTarget.castShadow = false;
    planeRenderTarget.parent = camera;
    planeRenderTarget.renderOrder = 1;
    const urls = ["px.png", "nx.png", "py.png", "ny.png", "pz.png", "nz.png"];
    const cubeMap = new CubeTextureLoader()
        .setPath(`https://cdn.thema-optical.com/images/envMap/`)
        .load(urls, () => {
        cubeMap.encoding = sRGBEncoding;
        const lightProbe = new LightProbe();
        scene.add(lightProbe);
        lightProbe.copy(LightProbeGenerator.fromCubeTexture(cubeMap));
        lightProbe.intensity = ENV.isForApp ? 1 : 13;
        scene.environment = cubeMap;
    });
    render.shadowMap.enabled = !ENV.isForApp;
    render.setSize(width, height);
    render.outputEncoding = sRGBEncoding;
    render.shadowMap.type = PCFSoftShadowMap;
    render.shadowMap.autoUpdate = false;
    render.sortObjects = true;
    render.toneMapping = CineonToneMapping;
    render.toneMappingExposure = 1.3;
    render.setClearColor(0xdbdbdb, 0);
    render.setPixelRatio(ENV.isForApp ? window.devicePixelRatio : window.devicePixelRatio / pixelRatioRank[gpuTier.tier]);
}
export { render, isWebgl2Supported, rTarget, composer2, planeRenderTarget, checkGpu, gpuTier, setupRender };
