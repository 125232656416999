import { __decorate } from "tslib";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Matrix4, REVISION, Vector3, } from "three/src/Three";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Stats from "three/examples/jsm/libs/stats.module";
import { gltfLoader } from "./canvas/loaders";
import PrintComponent from "./PrintComponent.vue";
// Import Scene Elements
import { scene, camera, controls, 
// width,
// height,
// initialAspect,
setupScene, setCamera, setLights, setPianiMesh, } from "./canvas/scene";
// Import Materials
import { initMaterials, frontMaterial, projectionMaterial, } from "./canvas/materials";
// Import Textures
import { initTextures } from "./canvas/textures";
// Import Render Elements
import { render, rTarget, planeRenderTarget, setupRender, checkGpu, gpuTier, isWebgl2Supported, } from "./canvas/render";
// Import Constants
import * as ENV from "../libModules/constants";
// Import Front Model Elements
import { front, frontGeometry, frontTemplePositionDummy, frontTexture, frontTextureTransmission, frontTextureRoughness, frontTextureGlitter, frontTexturePearl, loadFrontModel, setFrontalTexture, changeFinitura, glasses, setFrontalePosition, initGlasses, } from "./canvas/front";
// Import Lenses Elements
import { lensGeometry, loadLenses, changeLensesMaterial, lensMaterial, } from "./canvas/lenses";
// Import Temples Elements
import { templeModel, templeLeft, templeRight, templesMaterial, abbellitoreLeft, abbellitoreRight, abbellitoreBLeft, abbellitoreBRight, astaPlasticaLeft, astaPlasticaRight, astaMetalloTerminaleSx, astaMetalloTerminaleDx, loadTemples, removeTemples, setTemplesTexture, changeColoreMetalloAste, changeFinituraAste, changeMaterialeAste, changeAbbellitore, goldColorCode, abbellitoreGoldColorCode, abbellitoreGunColorCode, } from "./canvas/temples";
import { face, loadMesh } from "./canvas/faceMesh";
import axios from "axios";
import ajax_request from "@/plugins/ajax_request";
/**
 * Inizializza il canvas, lancia script di base...
 */
let CanvasComponent = class CanvasComponent extends Vue {
    constructor() {
        super(...arguments);
        /**
         * Main THREE elements
         */
        this.threeVersion = REVISION;
        /**
         * Utils
         */
        this.componentsCount = 5;
        this.componentsLoaded = 0;
        this.width = 0;
        this.height = 0;
        this.loadingGeometry = 0; // % caricament;
        this.startLoading = false; // definisce quando deve partire il dialog di caricamento geometrie
        this.updateTemplesGeometry = false; //definisce quando è necessario aggiornare le geometrie delle aste
        this.haveShadow = false; // variabile di controllo del render dell'obra
        this.loadedModel = false;
        this.printed = false;
        this.share = false;
        this.renderRequested = false; // Ricorda se è stato richiesto un render, in modo da non richiedere multipli render contemporaneamente sprecando risorse
        this.badGpu = false; // Se true, il dispositivo non è in grado di gestire il configuratore con orbitControls: abilitiamo delle frecce per ruotare l'occhiale
        this.showArrows = false; // Se true, mostra le frecce per ruotare l'occhiale
        this.currentRotation = 0;
        // TODO: Il caricamento va fatto basandosi sulla versione del pacchetto altrimenti se rilasciata per node non funziona
        this.stats = this.enableStats ? Stats() : undefined;
        this.initialAspect = 1;
    }
    created() {
        // Setup the scene and initialize it
        setupScene(this.shouldLoadScene).then(() => {
            console.warn("Completata static data, login utente + lancio init...");
            if (!ENV.isForApp)
                this.getUserData();
            initTextures();
            initMaterials();
            // Wait to make sure the webview is loaded
            if (ENV.isForApp)
                setTimeout(this.init, 250);
            else
                this.init();
        });
    }
    beforeDestory() {
        scene.clear();
        frontGeometry?.dispose();
        lensGeometry?.dispose();
        frontTexture?.dispose();
        frontTextureTransmission?.dispose();
        frontTextureRoughness?.dispose();
        frontTextureGlitter?.dispose();
        frontTexturePearl?.dispose();
        render.dispose();
    }
    set abbellitore(v) {
        this.$store.state.configurator.abbellitore = v;
    }
    get abbellitore() {
        return this.$store.state.configurator.abbellitore;
    }
    get tipoAbbellitore() {
        return this.$store.state.configurator.tipoAbbellitore;
    }
    set tipoAbbellitore(v) {
        this.$store.state.configurator.tipoAbbellitore = v;
    }
    /**
     * METHODS
     */
    async init() {
        const container = document.getElementById("canvas-container") ||
            document.createElement("div");
        this.width = ENV.isForApp
            ? window.innerWidth
            : this.$refs.container.offsetWidth;
        this.height = ENV.isForApp
            ? window.innerHeight
            : this.$refs.container.offsetHeight;
        this.glassesScale = new Vector3(100, 100, 100);
        this.scale = new Vector3(1, 1, 1);
        this.faceMatrix = new Matrix4();
        // If stats are enabled append them to the DOM
        if (this.enableStats && this.stats)
            container.appendChild(this.stats.dom);
        // Check how powerful the gpu is
        await checkGpu();
        // Check if the gpu available on the device is bad
        this.badGpu = gpuTier?.tier == 0;
        this.showArrows = this.badGpu && !ENV.isForApp;
        if (!this.shouldLoadScene)
            setPianiMesh();
        // Set the lights
        await setLights();
        // Set the camera
        await setCamera(this.width, this.height, this.shouldLoadScene);
        // Setup the render flow
        setupRender(this.width, this.height);
        // Append the renderer
        container.appendChild(render.domElement);
        // The next line adds a bug on configurators on mobile devices:
        //    when the user scrolls the page the canvas gets a wrong
        //    size/ratio.
        // window.addEventListener("resize", this.onWindowResize);
        if (!ENV.isForApp) {
            // Get all items
            await this.getFrontalModels();
            await this.getLenses();
            // Check if the user is trying to load a custom configuration from QRCode
            const fromQRCODE = this.getQRCode();
            if (!fromQRCODE && !ENV.isForApp) {
                // If not, load a default front model (or a random one, if defaultFrontModel is null)
                await this.setAnItem(this.defaultFrontModel);
            }
            else {
                // If yes, load all the colors: they won't be automatically loaded since a color is already selected
                await this.getColors();
            }
            await this.getFrontalModelsSizes();
            await this.getTemples();
            if (!fromQRCODE && !ENV.isForApp) {
                this.setAnItemLens(this.defaultSunLenses);
            }
            // When the OrbitControls change, request a render
            controls.addEventListener("change", this.requestRender);
            // Request a render on window resize
            window.addEventListener("resize", this.requestRender);
            // Listen to resize events on the canvas-container element
            const resizeObserver = new ResizeObserver((_entries) => {
                // Update variables based on the new size
                camera.aspect =
                    this.$refs.container.offsetWidth /
                        this.$refs.container.offsetHeight;
                if (this.$refs.container.offsetWidth != this.width)
                    this.scale.x = camera.aspect / this.initialAspect;
                if (this.$refs.container.offsetHeight != this.height)
                    this.scale.y = camera.aspect / this.initialAspect;
                render.setSize(this.$refs.container.offsetWidth, this.$refs.container.offsetHeight);
                // if (isWebgl2Supported) {
                //   rTarget.setSize(
                //     (this.$refs.container as HTMLElement).offsetWidth,
                //     (this.$refs.container as HTMLElement).offsetHeight
                //   );
                // }
                this.initialAspect =
                    this.$refs.container.offsetWidth /
                        this.$refs.container.offsetHeight;
                planeRenderTarget.scale.set(this.scale.x, this.scale.y, this.scale.z);
                // Update the projection matrix of the camera
                camera.updateProjectionMatrix();
                // Render the frame
                this.requestRender();
            });
            // The only element we want to observe is the canvas-container
            resizeObserver.observe(document.getElementById("canvas-container"));
            // First animation of OrbitControls
            controls.update();
        }
        // First animation
        this.requestRender();
        // DO NOT DELETE - Flutter needs this to understand whether the page is loaded or not
        console.log("PageLoaded");
    }
    mod(n, m) {
        return ((n % m) + m) % m;
    }
    rotateLeft() {
        // Rotate left by 45° - but if the color category is "MULTISTRATO" don't allow to see behind the glasses
        if (this.getActiveConfiguration &&
            (this.getActiveConfiguration?.colore?.Category != "MULTISTRATO" ||
                (this.getActiveConfiguration?.colore?.Category == "MULTISTRATO" &&
                    this.currentRotation > 7) ||
                this.currentRotation < 3)) {
            this.currentRotation = this.mod(this.currentRotation - 1, 8);
            scene.rotateY(3.14 / 4);
            this.requestRender();
        }
    }
    rotateRight() {
        // Rotate right by 45° - but if the color category is "MULTISTRATO" don't allow to see behind the glasses
        if (this.getActiveConfiguration &&
            (this.getActiveConfiguration?.colore?.Category != "MULTISTRATO" ||
                (this.getActiveConfiguration?.colore?.Category == "MULTISTRATO" &&
                    this.currentRotation < 2) ||
                this.currentRotation > 5)) {
            this.currentRotation = this.mod(this.currentRotation + 1, 8);
            scene.rotateY(-3.14 / 4);
            this.requestRender();
        }
    }
    requestRender() {
        // If a render has already been requested, we don't need to ask for it again
        if (!this.renderRequested) {
            this.renderRequested = true;
            requestAnimationFrame(this.animate);
        }
    }
    animate() {
        this.renderRequested = false;
        // Update orbit controls
        if (!ENV.isForApp)
            controls.update();
        // Update the stats if they are enabled (debug mode)
        if (this.stats)
            this.stats.update();
        if (front &&
            this.haveShadow &&
            templeLeft.children.length > 0 &&
            templeRight.children.length > 0) {
            let zPositive = camera.position.z >= -20;
            front.visible = !zPositive;
            planeRenderTarget.visible = false;
            templeModel.visible = zPositive;
            front.material = frontMaterial;
            astaPlasticaRight.material = astaPlasticaLeft.material = templesMaterial;
            if (astaMetalloTerminaleSx && astaMetalloTerminaleDx) {
                astaMetalloTerminaleSx.material = astaMetalloTerminaleDx.material =
                    templesMaterial;
            }
            if (this.abbellitore != null) {
                abbellitoreBLeft.visible = abbellitoreBRight.visible = !zPositive;
                abbellitoreLeft.visible = abbellitoreRight.visible = false;
            }
            else {
                if (abbellitoreBLeft) {
                    abbellitoreBLeft.visible = abbellitoreBRight.visible = false;
                }
                if (abbellitoreLeft) {
                    abbellitoreLeft.visible = abbellitoreRight.visible = !zPositive;
                }
            }
            if (isWebgl2Supported) {
                render.setRenderTarget(rTarget);
                render.render(scene, camera);
                render.setRenderTarget(null);
            }
            front.visible = true;
            planeRenderTarget.visible = false;
            templeModel.visible = true;
            if (isWebgl2Supported) {
                if (zPositive) {
                    if (astaMetalloTerminaleSx && astaMetalloTerminaleDx) {
                        astaMetalloTerminaleSx.material = astaMetalloTerminaleDx.material =
                            projectionMaterial;
                    }
                    astaPlasticaLeft.material = astaPlasticaRight.material =
                        projectionMaterial;
                    front.material = frontMaterial;
                }
                else {
                    if (astaMetalloTerminaleSx && astaMetalloTerminaleDx) {
                        astaMetalloTerminaleSx.material = astaMetalloTerminaleDx.material =
                            templesMaterial;
                    }
                    astaPlasticaLeft.material = astaPlasticaRight.material =
                        templesMaterial;
                    front.material = projectionMaterial;
                }
            }
            else {
                front.material = frontMaterial;
                if (astaMetalloTerminaleSx && astaMetalloTerminaleDx) {
                    astaMetalloTerminaleSx.material = astaMetalloTerminaleDx.material =
                        templesMaterial;
                }
                astaPlasticaLeft.material = astaPlasticaRight.material =
                    templesMaterial;
            }
            if (this.abbellitore != null) {
                abbellitoreBLeft.visible = abbellitoreBRight.visible = zPositive;
                abbellitoreLeft.visible = abbellitoreRight.visible = false;
            }
            else {
                if (abbellitoreBLeft) {
                    abbellitoreBLeft.visible = abbellitoreBRight.visible = false;
                }
                if (abbellitoreLeft) {
                    abbellitoreLeft.visible = abbellitoreRight.visible = zPositive;
                }
            }
        }
        else if (front) {
            // consente di renderizzare l'ombra di tutto l'occhiale al caricamento del modello
            this.haveShadow = true;
        }
        render.render(scene, camera);
        if (this.positionForPrint) {
            // If positionForPrint is set to true, move the camera to a predefined position
            camera.position.lerp(new Vector3(123, 47, 163), 0.075);
            // Keep rendering until we get to a position close to the expected one
            if (camera.position.x >= 115 &&
                camera.position.y >= 40 &&
                camera.position.z >= 155) {
                this.setPositionForPrint(false);
            }
            else {
                console.log(camera.position);
                this.requestRender();
            }
        }
    }
    doRender() {
        render.render(scene, camera);
    }
    getQRCode() {
        // let self = this;
        let url = window.location.href;
        let id = +url.split("/")[url.split("/").length - 1];
        //nel caso in cui sia richiesta una configurazione via qr-code la carico
        //altrimenti viene caricato un modello casuale da Frontali
        if (id > 0) {
            this.getCustomConfigurations(id);
            return true;
        }
        return false;
    }
    // Funzione per il caricamento di file gltf
    async loadModel() {
        // TODO: il materiale serve per i caricamenti successivi?
        // frontMaterial?.forEach((element) => {
        //   element?.dispose();
        // });
        // TODO: il materiale (lenti) serve per caricamenti successivi?
        // this.lensMaterial?.dispose();
        // sostituisco SC con C perchè i gltf dei modelli da sole
        // sono identici a quelli da vista, evito di duplicare file gltf
        const frontale = await gltfLoader.loadAsync(ENV.isForApp ? `${ENV.modelsUrl}${this.frontModelLinea}/${this.frontModel.replace(/([0-9]+)(S)/i, "$1")}.gltf`
            : `${ENV.modelsUrl}${ENV.configIdentifier}/${this.frontModel.replace(/([0-9]+)(S)/i, "$1")}${ENV.configSite == 'THEMA' ? 'C' : ''}.gltf`);
        await loadFrontModel(frontale, this.glassesScale, this.finitura);
        loadLenses(frontale);
        await loadTemples(this.glassesScale, this.frontale?.U_THE_LINEA);
        changeAbbellitore(this.abbellitore);
        // scene.add(templeLeft, templeRight);
        // scene.add(frontale.scene);
        if (ENV.isForApp) {
            face.add(glasses);
        }
        else {
            scene.add(glasses);
        }
        this.haveShadow = false;
        if (!this.frontColor) {
            // If an override was made for the front color, use it
            if (this.defaultColors.frontColor) {
                await this.setColor(this.defaultColors.frontColor);
            }
            else if (this.defaultFrontColor) {
                // If no override was made, use the default color of the prop
                await this.setColor(this.defaultFrontColor);
            }
        }
        if (!this.templesColor) {
            // If an override was made for the temples color, use it
            if (this.defaultColors.templesColor) {
                await this.setTemplesColor(this.defaultColors.templesColor);
            }
            else if (this.defaultTemplesColor) {
                // If no override was made, use the default color of the prop
                await this.setTemplesColor(this.defaultTemplesColor);
            }
        }
        else if (this.templesColor) {
            // Setta texture e materiale: necessario per far funzionare "configurazione da qrcode",
            // in quanto in quel caso partiamo da una configurazione già esistente
            await setTemplesTexture(this.templesColor, this.frontColor, this.materialeAste, this.coloreMetalloAste);
            if (this.abbellitore) {
                this.abbellitore =
                    this.coloreMetalloAste == goldColorCode
                        ? abbellitoreGoldColorCode
                        : abbellitoreGunColorCode;
            }
            changeMaterialeAste(this.materialeAste, this.coloreMetalloAste, this.templesColor.U_THE_COL_TYPE1);
        }
        await setFrontalTexture(this.frontColor, this.finitura, this.colorCategory);
        this.loadedModel = true;
        // DO NOT DELETE - Flutter needs it to understand whether the frontal is loaded
        console.info("LoadedFrontal");
    }
    // TODO: non credo questa funzione sia utilizzata. Ci serve?
    onWindowResize() {
        var container = document.getElementById("canvas-container");
        let w = container.clientWidth;
        let h = window.innerHeight;
        camera.aspect = w / h;
        camera.updateProjectionMatrix();
        if (isWebgl2Supported) {
            rTarget.setSize(this.$refs.container.offsetWidth, this.$refs.container.offsetHeight);
        }
        render.setSize(w, h);
        this.requestRender();
    }
    /**
     * WATCHERS
     */
    async frontModelChanged(newVal, oldVal) {
        if (this.frontale == null) {
            glasses.clear();
            return;
        }
        const updateFrontModel = async () => {
            scene.remove(scene.getObjectByName("GLASS"));
            if (ENV.multiLineTemples) {
                removeTemples();
            }
            frontGeometry?.dispose();
            frontTemplePositionDummy?.geometry?.dispose();
            frontTemplePositionDummy?.material?.dispose();
            //carico i componenti nella scena
            this.loadingGeometry = 5; //start loading
            await this.loadModel();
            // await loadTemples(new Vector3(1,1,1,), 'DF')
            this.requestRender();
            // Richiedi un altro frame dopo 150 ms, altrimenti il secondo render
            // non riesce a stare dietro al cambiamento
            setTimeout(this.requestRender, 150);
            setTimeout(this.requestRender, 300);
        };
        if (oldVal?.U_THE_MODELLO) {
            const oldModel = oldVal?.U_THE_MODELLO
                .replace(/([0-9]+)(S)/i, "$1")
                .replace(/([0-9]+)(C)/i, "$1"), newModel = newVal?.U_THE_MODELLO
                .replace(/([0-9]+)(S)/i, "$1")
                .replace(/([0-9]+)(C)/i, "$1");
            // verifico che sia cambiato il modello e che non sia solamente stata scelta la
            // versione da sole dello stesso modello
            if (oldModel != newModel) {
                // Controllo se il calibro e naso selezionati siano disponibili per il nuovo modello
                const filtered = this.itemsSizes.filter((s) => {
                    return s.ItemCode == this.frontale.ItemCode;
                });
                if (filtered.find((element) => `${element.size_eye}/${element.size_bridge}` == this.frontSize) == undefined) {
                    // Se non lo sono, pulisco le dimensioni
                    this.clearSize();
                }
                //rimuovo i componenti dalla scena
                await updateFrontModel();
            }
            else {
                // Stesso modello di prima, non devo fare nulla
                // DO NOT DELETE - Flutter needs it to understand whether the frontal is loaded
                console.info("LoadedFrontal");
            }
        }
        else {
            await updateFrontModel();
        }
    }
    async frontColorChanged() {
        if (!ENV.isForApp) {
            if (this.getActiveConfiguration &&
                this.getActiveConfiguration?.colore?.Category == "MULTISTRATO") {
                if (!this.badGpu) {
                    if (controls.maxAzimuthAngle !== Math.PI / 4) {
                        camera.position.z = 200;
                        camera.position.y = 60;
                        camera.position.x = 150;
                    }
                    controls.maxAzimuthAngle = Math.PI / 6;
                    controls.minAzimuthAngle = (Math.PI / 6) * -1;
                }
                else {
                    while (this.currentRotation != 0) {
                        this.currentRotation = this.mod(this.currentRotation + 1, 8);
                        scene.rotateY(-3.14 / 4);
                    }
                }
            }
            else {
                controls.maxAzimuthAngle = Infinity;
                controls.minAzimuthAngle = Infinity;
            }
        }
        await setFrontalTexture(this.frontColor, this.finitura, this.colorCategory);
        this.requestRender();
    }
    async finituraChanged(newVal) {
        await changeFinitura(newVal, this.frontColor);
        this.requestRender();
    }
    async finituraAsteChanged(newVal) {
        await changeFinituraAste(newVal);
        this.requestRender();
    }
    async templesColorChanged(newVal) {
        // Se il materiale aste è plastica, setto a null coloreMetalloAste
        if (this.materialeAste == "PL") {
            this.unsetTemplesMetalColor();
        }
        if (this.abbellitore) {
            this.abbellitore =
                this.coloreMetalloAste == goldColorCode
                    ? abbellitoreGoldColorCode
                    : abbellitoreGunColorCode;
        }
        changeMaterialeAste(this.materialeAste, this.coloreMetalloAste, this.templesColor.U_THE_COL_TYPE1);
        await setTemplesTexture(newVal, this.frontColor, this.materialeAste, this.coloreMetalloAste);
        this.requestRender();
    }
    setTemplesMaterial(newVal) {
        if (this.abbellitore) {
            this.abbellitore =
                this.coloreMetalloAste == goldColorCode
                    ? abbellitoreGoldColorCode
                    : abbellitoreGunColorCode;
        }
        changeMaterialeAste(newVal, this.coloreMetalloAste, this.templesColor.U_THE_COL_TYPE1);
        this.requestRender();
    }
    coloreMetalloAsteChanged(newVal) {
        if (this.abbellitore) {
            this.abbellitore =
                newVal == goldColorCode
                    ? abbellitoreGoldColorCode
                    : abbellitoreGunColorCode;
        }
        changeColoreMetalloAste(newVal);
        this.requestRender();
    }
    async printedChanged() {
        if (this.printed) {
            Vue.nextTick(() => {
                let iframe = document.getElementById("print-frame");
                // Prepare the iframe content
                let toAppend = this.$el.querySelector("#print-component");
                // Set a custom basic style
                let stylecss = `
              #print-component { display: block !important;}
              body{font-family: arial;}
              img{width: 100%;}
              canvas{width: 100%}
              h1{float: left}
              .color-img {width: 30%;}
              table{width: 100%; border-collapse: collapse; margin-bottom: 15px}
              td, th {border: 1px solid #dddddd;text-align: left;padding: 8px;}
              tr:nth-child(odd) {background: #dddddd;}
              .logo{width:20%; float: right;}
            `;
                // Generate the html
                //eslint-disable-next-line
                let html = `<html><head><style>${stylecss}</style><body>${toAppend?.innerHTML}<script>document.body.onload = () => {window.print();};<\/script></body></head></html>`;
                iframe.srcdoc = html;
                this.printed = false;
                this.setPrint(false);
            });
        }
    }
    async shareConfigurationChanged() {
        Vue.nextTick(async () => {
            // The render phase is necessary, otherwise canvas.toDataURL
            // returns an empty image
            render.render(scene, camera);
            // Get the image from the canvas
            let canvas = document.getElementsByTagName("canvas")[0];
            // Put the image into the data object we will send to SendGrid
            const sendgridData = this.shareConfiguration.data;
            sendgridData["image"] = canvas.toDataURL();
            // Send the email using template and api key specified in the environment variables
            const result = await ajax_request.sendSendGridMail(sendgridData, this.shareConfiguration.emailFrom, this.shareConfiguration.emailTo, ENV.sendgridShareMailTemplateID, ENV.sendgridAPIKey);
            // Set "emailSent" to true if the operation was successful
            this.setEmailSent(result.status == 200);
        });
    }
    async setLensesMaterial(newVal) {
        if (this.frontModel) {
            // Se lensMaterial è ancora null, carica le lenti
            if (!lensMaterial) {
                const frontale = await gltfLoader.loadAsync(`${ENV.modelsUrl}${this.frontModelLinea}/${this.frontModel.replace(/([0-9]+)(S)/i, "$1")}.gltf`);
                loadLenses(frontale);
            }
            await changeLensesMaterial(newVal);
            this.requestRender();
        }
    }
    /**
     * Update and reload the faceMesh
     * @param {Object} newVal
     */
    meshVertexTrianglesChanged(newVal) {
        if (face == null) {
            this.arrayVertexIndexMesh = newVal;
            loadMesh(this.arrayVertexIndexMesh[0], this.arrayVertexIndexMesh[1][0], this.arrayVertexIndexMesh[1][1]);
        }
        if (face != null) {
            face.matrix = this.faceMatrix;
        }
        this.requestRender();
    }
    /**
     * Send information about pupillar distance, temple distance and nose distance
     * @param {Object} newVal
     */
    userInfoChanged(newVal) {
        this.distancePup = newVal.distancePup;
        this.distanceTemp = newVal.distanceTemp;
        this.distanceNose = newVal.distanceNose;
    }
    rotationMatrixChanged(newVal) {
        if (face != null) {
            var p11 = newVal[11] * -1;
            newVal[11] = 0;
            this.faceMatrix.set(newVal[0], newVal[1], newVal[2], newVal[3], newVal[4], newVal[5], newVal[6], newVal[7], newVal[8], newVal[9], newVal[10], newVal[11], newVal[12], newVal[13], newVal[14], newVal[15]);
            face.matrixAutoUpdate = false;
            camera.position.z = p11;
            this.requestRender();
        }
    }
    /**
     * Aggiorno la scala dell'occhiale in base al valore di frontalInfo rapportato a frontalBaseSize
     * @param {Object} newVal
     * @param {Object} oldVal
     */
    frontalInfoChanged(newVal, oldVal) {
        let scaleX, scaleY;
        if (typeof this.frontalBaseSize != "undefined" &&
            typeof this.frontalInfo != "undefined") {
            scaleX =
                Number(newVal.frontWidth) / Number(this.frontalBaseSize.frontWidth); // + 0.06;
            scaleY =
                Number(newVal.verticalLensSize) /
                    Number(this.frontalBaseSize.verticalLensSize); // + 0.08;
            // Questi valori di scala sono stati scelti provando occhiali veri della stessa misura e confrontandoli con quelli del try-on
            if (!glasses) {
                initGlasses();
            }
            glasses.scale.set(scaleX, 0.95, scaleY);
            this.middleFrontalPoint = newVal.middleFrontalPoint;
            setFrontalePosition(newVal, this.distanceNose);
            this.requestRender();
        }
    }
    changedAbbellitore(newVal) {
        changeAbbellitore(newVal);
        this.requestRender();
    }
    changedTipoAbbellitore(newVal) {
        if (newVal == "B") {
            this.abbellitore =
                this.coloreMetalloAste == goldColorCode
                    ? abbellitoreGoldColorCode
                    : abbellitoreGunColorCode;
        }
        else {
            this.abbellitore = null;
        }
    }
    changedLinea(newVal) {
        axios.defaults.headers.common["line-id"] = newVal;
        axios.defaults.headers.common["site-id"] = newVal;
    }
    changedPositionForPrint(newVal) {
        if (newVal) {
            // If positionForPrint is true, it means we need to move the camera
            // to a predefined position. Everything is handled automatically by
            // the animate function, but we need to call it at least one time
            // to start the lerp
            this.requestRender();
        }
    }
};
__decorate([
    Prop()
], CanvasComponent.prototype, "setCanvasPos", void 0);
__decorate([
    Prop()
], CanvasComponent.prototype, "defaultFrontModel", void 0);
__decorate([
    Prop({ default: "PL0001" })
], CanvasComponent.prototype, "defaultFrontColor", void 0);
__decorate([
    Prop({ default: "EP0030" })
], CanvasComponent.prototype, "defaultTemplesColor", void 0);
__decorate([
    Prop()
], CanvasComponent.prototype, "defaultSunLenses", void 0);
__decorate([
    Prop({ default: false })
], CanvasComponent.prototype, "enableStats", void 0);
__decorate([
    Prop({ default: true })
], CanvasComponent.prototype, "shouldLoadScene", void 0);
__decorate([
    Watch("frontale", { deep: true })
], CanvasComponent.prototype, "frontModelChanged", null);
__decorate([
    Watch("frontColor")
], CanvasComponent.prototype, "frontColorChanged", null);
__decorate([
    Watch("finitura")
], CanvasComponent.prototype, "finituraChanged", null);
__decorate([
    Watch("finituraAste")
], CanvasComponent.prototype, "finituraAsteChanged", null);
__decorate([
    Watch("templesColor")
], CanvasComponent.prototype, "templesColorChanged", null);
__decorate([
    Watch("materialeAste")
], CanvasComponent.prototype, "setTemplesMaterial", null);
__decorate([
    Watch("coloreMetalloAste")
], CanvasComponent.prototype, "coloreMetalloAsteChanged", null);
__decorate([
    Watch("printed")
], CanvasComponent.prototype, "printedChanged", null);
__decorate([
    Watch("shareConfiguration")
], CanvasComponent.prototype, "shareConfigurationChanged", null);
__decorate([
    Watch("lenteSole", { deep: true })
], CanvasComponent.prototype, "setLensesMaterial", null);
__decorate([
    Watch("meshVertexTriangles", { deep: true })
], CanvasComponent.prototype, "meshVertexTrianglesChanged", null);
__decorate([
    Watch("userInfo", { deep: true })
], CanvasComponent.prototype, "userInfoChanged", null);
__decorate([
    Watch("rotationMatrix")
], CanvasComponent.prototype, "rotationMatrixChanged", null);
__decorate([
    Watch("frontalInfo")
], CanvasComponent.prototype, "frontalInfoChanged", null);
__decorate([
    Watch("abbellitore")
], CanvasComponent.prototype, "changedAbbellitore", null);
__decorate([
    Watch("tipoAbbellitore")
], CanvasComponent.prototype, "changedTipoAbbellitore", null);
__decorate([
    Watch("overrideLinea")
], CanvasComponent.prototype, "changedLinea", null);
__decorate([
    Watch("positionForPrint")
], CanvasComponent.prototype, "changedPositionForPrint", null);
CanvasComponent = __decorate([
    Component({
        name: "Canvas3d",
        computed: {
            ...mapGetters("configurator", {
                frontModel: "frontModel",
                frontModelLinea: "frontModelLinea",
                frontColor: "frontColor",
                frontSize: "frontSize",
                defaultColors: "defaultColors",
                overrideLinea: "overrideLinea",
                templesColor: "templesColor",
                materialeAste: "templesMaterial",
                getActiveConfiguration: "getActiveConfiguration",
                colorCategory: "colorCategory",
            }),
            ...mapState("configurator", {
                frontale: "frontale",
                coloreMetalloAste: "coloreMetalloAste",
                finitura: "finitura",
                finituraAste: "finituraAste",
                qrcode: "qrcode",
                frontals: "frontali",
                lenteSole: "lenteSole",
                userInfo: "userInfo",
                meshVertexTriangles: "meshVertexTriangles",
                rotationMatrix: "rotationMatrix",
                frontalBaseSize: "frontalBaseSize",
                frontalInfo: "frontalInfo",
                shareConfiguration: "shareConfiguration",
                print: "print",
                positionForPrint: "positionForPrint",
            }),
            ...mapState("configurator/frontali", {
                itemsSizes: "itemsSizes",
            }),
        },
        methods: {
            ...mapActions("configurator/frontali", {
                getFrontalModels: "getItems",
                setAnItem: "setAnItem",
                getFrontalModelsSizes: "getAllModelsSizes",
                setColor: "setColor",
                getColors: "getColors",
            }),
            ...mapActions("configurator/aste", {
                getTemples: "getAllColors",
                setTemplesColor: "setColor",
            }),
            ...mapActions("configurator/lenti", {
                getLenses: "getLenses",
                setAnItemLens: "setAnItem",
            }),
            ...mapActions("configurator/user", {
                getUserData: "getUserData",
            }),
            ...mapActions("configurator", {
                getCustomConfigurations: "getCustomConfigurations",
            }),
            ...mapMutations("configurator", {
                setQRCode: "setQRCode",
                clearSize: "clearSize",
                unsetTemplesMetalColor: "unsetTemplesMetalColor",
                setPositionForPrint: "setPositionForPrint",
                setPrint: "setPrint",
                setEmailSent: "setEmailSent"
            }),
        },
        components: {
            PrintComponent
        },
    })
], CanvasComponent);
export default CanvasComponent;
