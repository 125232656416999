/* eslint-disable prefer-const */
import * as THREE from "three";
import { camera, cmesh, cmesh_1, scene } from "./scene";
let face;
let geometry;
let vertex;
let importantMeshPoints;
/**
 * Load new faceMesh
 * @param fov
 * @param vect
 * @param index
 */
function loadMesh(fov, vect, index) {
    //Setto il fov della Camera
    setFovCamera(fov);
    // Se ci troviamo in modalità debug, mostra la mesh con opacità 0.5
    // const isDeveloperMode = process.env.NODE_ENV == "development";
    const isDeveloperMode = false; // Per ora lascio a false per testare su app
    const material = new THREE.MeshBasicMaterial({
        color: 0xfeff00,
        //wireframe: true,
        opacity: isDeveloperMode ? 1 : 0,
        colorWrite: isDeveloperMode,
        transparent: false,
    });
    // Creo la mesh con una geometria vuota
    face = new THREE.Mesh(geometry, material);
    face.renderOrder = 0;
    scene.add(face);
    face.geometry.setIndex(index);
    const v = new Float32Array(vect.flat().length);
    for (let i = 0; i < vect.length; i++) {
        let index = i * 3;
        let element = vect[i];
        let x = element[0] * 1000;
        let y = element[1] * 1000;
        let z = element[2] * 1000;
        if (vect.length == 468 && Math.abs(x) > 60) {
            v[index] = x > 0 ? 60 : -60;
        }
        // eslint-disable-next-line no-constant-condition
        else if (z < 0 && Math.abs(x) > 40)
            v[index] = x > 0 ? 40 : -40;
        else
            v[index] = x;
        v[index + 1] = y;
        v[index + 2] = z;
    }
    let importantLandmarks;
    // eslint-disable-next-line prefer-const
    importantLandmarks = vect.length == 468 ? [
        197, 419, 196, 6, 351, 122, 6, 351, 122, 197, 419, 196,
    ] : [
        36,
        855,
        427,
        14,
        818,
        387,
        13,
        607,
        158,
        12,
        606,
        157,
    ];
    importantMeshPoints = [];
    importantLandmarks.forEach((el) => {
        let t = [v[el * 3], v[el * 3 + 1], v[el * 3 + 2]];
        importantMeshPoints.push(t);
    });
    vertex = new THREE.Float32BufferAttribute(v, 3);
    face.geometry.setAttribute("position", vertex);
    face.geometry.attributes.position.needsUpdate = true;
    // this.face.rotateY(-1.57); // 90deg
    // this.face.rotateY(-0.785398); // 45deg
    face.geometry.attributes.position.needsUpdate = true;
    face.add(cmesh);
    face.add(cmesh_1);
}
/**
 * Set the fov camera
 * @param {Object} fov
 */
function setFovCamera(fov) {
    //Setto il fov della Camera
    camera.fov = fov;
    camera.updateProjectionMatrix();
}
export { face, geometry, vertex, importantMeshPoints, loadMesh };
