import ajax from '../../plugins/ajax_request';
import { EventBus } from '../../plugins/EventBus';
import * as ENV from '../../libModules/constants';
import { isEmpty, random } from 'lodash';
// class TempleState {
//   allColors!: Array<Temple>[];
//   extSxName = '';
//   extSxNameColor = '';
//   intSxName = '';
//   intSxNameColor = '';
//   extDxName = '';
//   extDxNameColor = '';
//   intDxName = '';
//   intDxNameColor = '';
// }
const state = {
    allColors: [],
    extSxName: '',
    extSxNameColor: '',
    extSxNameFont: 'Batang',
    intSxName: '',
    intSxNameColor: '',
    intSxNameFont: 'Batang',
    extDxName: '',
    extDxNameColor: '',
    extDxNameFont: 'Batang',
    intDxName: '',
    intDxNameColor: '',
    intDxNameFont: 'Batang',
};
const getters = {
    checkErrors(state, getters, rootState, rootGetters) {
        const errors = [];
        if (isEmpty(rootState.configurator.coloreAste)) {
            errors.push({
                label: 'color',
                message: 'Il colore è obbligatorio'
            });
        }
        if (isEmpty(rootState.configurator.finituraAste)) {
            errors.push({
                label: 'finishing',
                message: 'La finitura è obbligatoria'
            });
        }
        return errors;
    }
};
const mutations = {
    setExtSxName(state, extSxName) {
        state.extSxName = extSxName;
    },
    setExtSxNameColor(state, extSxNameColor) {
        state.extSxNameColor = extSxNameColor;
    },
    setExtSxNameFont(state, extSxNameFont) {
        state.extSxNameFont = extSxNameFont;
    },
    setIntSxName(state, intSxName) {
        state.intSxName = intSxName;
    },
    setIntSxNameColor(state, intSxNameColor) {
        state.intSxNameColor = intSxNameColor;
    },
    setIntSxNameFont(state, intSxNameFont) {
        state.intSxNameFont = intSxNameFont;
    },
    setExtDxName(state, extDxName) {
        state.extDxName = extDxName;
    },
    setExtDxNameColor(state, extDxNameColor) {
        state.extDxNameColor = extDxNameColor;
    },
    setExtDxNameFont(state, extDxNameFont) {
        state.extDxNameFont = extDxNameFont;
    },
    setIntDxName(state, intDxName) {
        state.intDxName = intDxName;
    },
    setIntDxNameColor(state, intDxNameColor) {
        state.intDxNameColor = intDxNameColor;
    },
    setIntDxNameFont(state, intDxNameFont) {
        state.intDxNameFont = intDxNameFont;
    }
};
const actions = {
    async getAllColors({ state }) {
        try {
            const res = await ajax.getTemplesColors();
            state.allColors = res.data;
            EventBus.$emit('temples-loaded');
            state.allColors.forEach((t) => {
                if (!ENV.multiLineTemples) {
                    t.imageUrl = `${ENV.texturesUrl}${t.U_THE_COLORE}.jpg`;
                }
                else {
                    t.imageUrl = `${ENV.texturesUrl}${t.U_THE_LINEA}-${t.U_THE_COLORE}-${t.U_THE_COL_TYPE1}.jpg`;
                }
            });
        }
        catch (error) {
            console.error('Error on get temples', error);
            EventBus.$emit('temples-loaded-error', error);
        }
    },
    async setColor({ state, rootState, dispatch }, color) {
        if (state.allColors.length == 0) {
            await dispatch('getAllColors');
        }
        console.log("SETTING COLOR", color, state.allColors.find((c => c.U_THE_COLORE == color)));
        if (color) {
            state.allColors.forEach(c => {
                if ((c.U_THE_COLORE == color && c.METALTEMPLE == '0') || (c.ItemCode == color && c.METALTEMPLE == '1' && ENV.multiLineTemples)) {
                    rootState.configurator.coloreAste = c;
                    rootState.configurator.misuraAste = c.U_THE_LUNGASTA;
                    console.log("FOUND COLOR", c);
                    return;
                }
            });
            state.allColors.forEach((c) => console.log(c.U_THE_COLORE));
        }
        else {
            const plasticColors = state.allColors.filter(c => c.METALTEMPLE == '0');
            const n = random(0, plasticColors.length - 1, false);
            rootState.configurator.coloreAste = plasticColors[n];
            rootState.configurator.misuraAste = plasticColors[n].U_THE_LUNGASTA;
        }
    }
};
export const aste = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
// export default aste;
