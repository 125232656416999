export default {
    componentsErrors(state, getters, rootState, rootGetters) {
        const allErrors = [];
        const aste = { temples: getters["aste/checkErrors"] };
        const frontal = { frontal: getters["frontali/checkErrors"] };
        allErrors.push(aste);
        allErrors.push(frontal);
        return allErrors;
    },
    frontalProgress: (state, getters, rootState, rootGetters) => {
        const totalOptions = 3;
        let selectedOptions = 0;
        if (state.frontale) {
            selectedOptions += 1;
        }
        if (state.calibro !== "" && state.naso !== "") {
            selectedOptions += 1;
        }
        if (state.asianFitting) {
            selectedOptions += 1;
        }
        return (selectedOptions / totalOptions) * 100;
    },
    frontalProgressComplete: (state, getters, rootState, rootGetters) => {
        return getters.frontalProgress == 100;
    },
    colorProgress: (state, getters, rootState, rootGetters) => {
        const totalOptions = 2;
        let selectedOptions = 0;
        if (state.finitura) {
            selectedOptions += 1;
        }
        if (state.colore) {
            selectedOptions += 1;
        }
        return (selectedOptions / totalOptions) * 100;
    },
    colorProgressComplete: (state, getters) => {
        return getters.colorProgress == 100;
    },
    frontModel: (state) => {
        return state.frontale ? state.frontale.U_THE_MODELLO : null;
    },
    frontModelLinea: (state) => {
        return state.frontale ? state.frontale.U_THE_LINEA : null;
    },
    frontSize: (state) => {
        if (state.calibro && state.naso) {
            return `${state.calibro}/${state.naso}`;
        }
    },
    frontColor: (state) => {
        return state.colore ? state.colore.U_THE_COLORE : null;
    },
    templesColor: (state) => {
        return state.coloreAste;
    },
    defaultColors: (state) => {
        return state.defaultColors;
    },
    overrideLinea: (state) => {
        return state.overrideLinea;
    },
    templesMaterial: (state) => {
        return state.templesMaterial;
    },
    haveConfigurations: (state) => {
        return state.configurations.length > 0;
    },
    colorCategory: (state) => state.colore?.Category,
    // userIsGuest: (state) => {
    //   return _.isEmpty(state.user);
    // },
    // cartTotal: (state) => {
    //   return state.cartRows.length;
    // },
    // getCartRows: (state) => {
    //   return state.cartRows;
    // },
    configurationComplete: (state) => {
        // Se l'occhiale è da sole, devono essere state selezionate le lenti da sole
        const a = (state.frontale &&
            state.calibro != "" &&
            state.naso != "" &&
            state.colore &&
            state.finitura &&
            state.coloreAste);
        return (state.frontale?.U_TIPO_VSP == "S") ? a && state.lenteSole != null : a && state.frontale?.U_TIPO_VSP == "V";
    },
    getActiveConfiguration: (state) => {
        // console.log('from store', c);
        return {
            frontale: state.frontale,
            calibro: state.calibro,
            naso: state.naso,
            asianFitting: state.asianFitting,
            colore: state.colore,
            finitura: state.finitura,
            coloreAste: state.coloreAste,
            coloreMetalloAste: state.coloreMetalloAste || '',
            finituraAste: state.finituraAste,
            misuraAste: state.misuraAste,
            abbellitore: state.abbellitore != null ? 'B' : '',
            nome: state?.frontali?.name || '',
            coloreNome: state?.frontali?.nameColor || '',
            fontNome: state?.frontali?.nameFont || 'Batang',
            nomeExtAstaSx: state.aste?.extSxName || '',
            coloreNomeExtAstaSx: state.aste?.extSxNameColor || '',
            fontNomeExtAstaSx: state.aste?.extSxNameFont || 'Batang',
            nomeIntAstaSx: state.aste?.intSxName || '',
            coloreNomeIntAstaSx: state.aste?.intSxNameColor || '',
            fontNomeIntAstaSx: state.aste?.intSxNameFont || 'Batang',
            nomeExtAstaDx: state.aste?.extDxName || '',
            coloreNomeExtAstaDx: state.aste?.extDxNameColor || '',
            fontNomeExtAstaDx: state.aste?.extDxNameFont || 'Batang',
            nomeIntAstaDx: state.aste?.intDxName || '',
            coloreNomeIntAstaDx: state.aste?.intDxNameColor || '',
            fontNomeIntAstaDx: state.aste?.intDxNameFont || 'Batang',
            note: state.note,
            lensSole: state.lenteSole,
            // LENTI
            lensesType: 'MONOFOCALE',
            lensdescrDx: state.lenteVistaDx ? state.lenteVistaDx.U_THE_MODELLO : '',
            lensdescrSx: state.lenteVistaSx ? state.lenteVistaSx.U_THE_MODELLO : '',
            lensDx: state.lenteVistaDx,
            lensSx: state.lenteVistaSx,
            // Specifiche lenti da vista
            sferaDx: state.lenti ? state.lenti.sferaDx : 0,
            sferaSx: state.lenti ? state.lenti.sferaSx : 0,
            cilindroDx: state.lenti ? state.lenti.cilindroDx : 0,
            cilindroSx: state.lenti ? state.lenti.cilindroSx : 0,
            asseDx: state.lenti ? state.lenti.asseDx : 0,
            asseSx: state.lenti ? state.lenti.asseSx : 0,
            semiDistDx: state.lenti ? state.lenti.semiDistDx : 0,
            semiDistSx: state.lenti ? state.lenti.semiDistSx : 0,
            altPupDx: state.lenti ? state.lenti.altPupDx : 0,
            altPupSx: state.lenti ? state.lenti.altPupSx : 0,
            addDx: state.lenti ? state.lenti.addDx : 0,
            addSx: state.lenti ? state.lenti.addSx : 0,
        };
    },
    // getActiveConfigurationToString: ({ getters }) => {
    //   var activeConf = getters.getActiveConfiguration();
    //   var activeConfJSON = JSON.stringify(activeConf);
    //   return activeConfJSON;
    // },
    /**
     * Check if the active configuration is saved
     * @param state Current state
     * @returns The index of the configuration if available, otherwise -1
     */
    isConfigurationSaved: (state) => {
        if (state.configurations.length > 0) {
            const c = {
                frontale: state.frontale,
                calibro: state.calibro,
                naso: state.naso,
                asianFitting: state.asianFitting,
                colore: state.colore,
                finitura: state.finitura,
                coloreAste: state.coloreAste,
                coloreMetalloAste: state.coloreMetalloAste || '',
                finituraAste: state.finituraAste,
                misuraAste: state.misuraAste,
                abbellitore: state?.abbellitore || '',
                nome: state?.frontali?.name || '',
                coloreNome: state?.frontali?.nameColor || '',
                fontNome: state?.frontali?.nameFont || 'Batang',
                nomeExtAstaSx: state.aste?.extSxName || '',
                coloreNomeExtAstaSx: state.aste?.extSxNameColor || '',
                fontNomeExtAstaSx: state.aste?.extSxNameFont || 'Batang',
                nomeIntAstaSx: state.aste?.intSxName || '',
                coloreNomeIntAstaSx: state.aste?.intSxNameColor || '',
                fontNomeIntAstaSx: state.aste?.intSxNameFont || 'Batang',
                nomeExtAstaDx: state.aste?.extSxName || '',
                coloreNomeExtAstaDx: state.aste?.extSxNameColor || '',
                fontNomeExtAstaDx: state.aste?.extSxNameFont || 'Batang',
                nomeIntAstaDx: state.aste?.intDxName || '',
                coloreNomeIntAstaDx: state.aste?.intDxNameColor || '',
                fontNomeIntAstaDx: state.aste?.intDxNameFont || 'Batang',
                note: state.note,
                lensSole: state.lenteSole,
            };
            const confIndex = state.configurations.findIndex((cfg) => {
                return (cfg.frontale == c.frontale && //modello frontale
                    cfg.calibro == c.calibro &&
                    cfg.naso == c.naso &&
                    cfg.asianFitting == c.asianFitting &&
                    cfg.colore == c.colore &&
                    cfg.finitura == c.finitura &&
                    cfg.coloreAste == c.coloreAste &&
                    cfg.coloreMetalloAste == c.coloreMetalloAste &&
                    cfg.finituraAste == state.finituraAste &&
                    cfg.misuraAste == c.misuraAste &&
                    cfg.abbellitore == c.abbellitore &&
                    cfg.nome == c.nome &&
                    cfg.coloreNome == c.coloreNome &&
                    cfg.fontNome == c.fontNome &&
                    cfg.nomeExtAstaSx == c.nomeExtAstaSx &&
                    cfg.coloreNomeExtAstaSx == c.coloreNomeExtAstaSx &&
                    cfg.fontNomeExtAstaSx == c.fontNomeExtAstaSx &&
                    cfg.nomeIntAstaSx == c.nomeIntAstaSx &&
                    cfg.coloreNomeIntAstaSx == c.coloreNomeIntAstaSx &&
                    cfg.fontNomeIntAstaSx == c.fontNomeIntAstaSx &&
                    cfg.nomeExtAstaDx == c.nomeExtAstaDx &&
                    cfg.coloreNomeExtAstaDx == c.coloreNomeExtAstaDx &&
                    cfg.fontNomeExtAstaDx == c.fontNomeExtAstaDx &&
                    cfg.nomeIntAstaDx == c.nomeIntAstaDx &&
                    cfg.coloreNomeIntAstaDx == c.coloreNomeIntAstaDx &&
                    cfg.fontNomeIntAstaDx == c.fontNomeIntAstaDx &&
                    cfg.note == c.note &&
                    cfg.lensSole == c.lensSole
                // &&
                // cfg.lensesType == c.lensesType &&
                // cfg.lensdescrDx == c.lensdescrDx &&
                // cfg.lensdescrSx == c.lensdescrSx &&
                // cfg.lensDx == c.lensDx &&
                // cfg.lensSx == c.lensSx &&
                // cfg.sferaDx == c.sferaDx &&
                // cfg.sferaSx == c.sferaSx &&
                // cfg.cilindroDx == c.cilindroDx &&
                // cfg.cilindroSx == c.cilindroSx &&
                // cfg.asseDx == c.asseDx &&
                // cfg.asseSx == c.asseSx &&
                // cfg.semiDistDx == c.semiDistDx &&
                // cfg.semiDistSx == c.semiDistSx &&
                // cfg.altPupDx == c.altPupDx &&
                // cfg.altPupSx == c.altPupSx &&
                // cfg.addDx == c.addDx &&
                // cfg.addSx == c.addSx
                );
            });
            return confIndex;
        }
        return -1;
    },
    urlParams: () => {
        /**
         * URL esempio url_base/<modello>/[F|f]-<colore_frontale>/<[A|a][M|m]-<colore_asta>-[GOLD|GUN]
         * - modello: codice modello così com'è
         * - colore frontale: F identifica che si tratta del colore frontale il codice va messo per esteso es: PL0010
         * - colore asta: A identifica che si tratta di colore asta, M è opzionale e indica se è di metallo, il colore va messo per esteso, GOLD o GUN specificano il colore del metallo
         */
        const url = window.location.href;
        const data = url.split("/");
        const components = {
            modello: null,
            coloreFrontale: null,
            coloreAste: null,
            materialeAste: null,
        };
        //id = id[id.length-1];
        data.forEach((element) => {
            if (element.match(/\w\w-\d\d\dC/)) {
                //se faccio il match con un presunto codice modello carico quel modello
                // l'espressione regolare è uguale nele configurazioni yii dell'urlManager
                /*let f = this.items.filter(fr => {
                  return fr.U_THE_MODELLO == element
                });*/
                //this.setFrontal(f[0]);
                components.modello = element;
            }
            else if (element.match(/[F|f]-PL\d{4}/)) {
                const cf = element
                    .match(/[F|f]-PL\d{4}/);
                //si dovrebbe trattare del colore frontale
                if (cf)
                    components.coloreFrontale = cf[0]
                        .toUpperCase()
                        .replace("F-", "");
            }
            else if (element.match(/[A|a][M|m]?-PL\d{4}(\w{3,4})?/)) {
                //si dovrebbe trattare del colore aste
                let c = element.match(/[A|a][M|m]?-PL\d{4}(\w{3,4})?/);
                if (c) {
                    c = c[0].split("-");
                    //c[0] = A or AM
                    //c[1] = cod colore
                    //c[2] = se ho la M contiene GUN o GOLD
                    components.coloreAste = c[1];
                    if (c[0].match(/[m|M]/)) {
                        components.materialeAste = c[2];
                    }
                }
            }
        });
        return components;
    },
};
