import * as ENV from "../../libModules/constants";
import { front, frontTemplePositionDummy, glasses } from "./front";
import { gltfLoader, textureLoader } from "./loaders";
import { abbellitoreGunMaterial, goldMaterial, gunMaterial, silverMaterial } from "./materials";
import { acetateMaterialAttributes } from "./material_attributes";
import { Group, LinearFilter, Matrix3, Quaternion, RepeatWrapping, sRGBEncoding, Vector3 } from "three/src/Three";
import { isWebgl2Supported } from "./render";
let templeModel = new Group();
let templeLeft = new Group();
let templeRight = new Group();
let templesMaterial;
let templesTexture;
let templesColor;
let finitura;
let templesTextureTransmission;
let templesTextureRoughness;
let animaLeft;
let animaRight;
let abbellitoreLeft;
let abbellitoreRight;
let abbellitoreBLeft;
let abbellitoreBRight;
let cernieraLeft;
let cernieraRight;
let astaMetalloLeft;
let astaMetalloRight;
let astaPlasticaLeft;
let astaPlasticaRight;
let astaMetalloTerminaleSx;
let astaMetalloTerminaleDx;
let currentLine;
let cernieraNormals;
const goldColorCode = "PRO0000280";
const gunColorCode = "PRO0000272";
const abbellitoreGoldColorCode = "PRO0000850";
const abbellitoreGunColorCode = "PRO0000849";
const abbellitoreName = "ABBELLITORE_DX";
const abbellitoreBName = "ABBELLITORE_DX_1";
const cernieraName = "CERNIERA_DX";
const astaPlasticaName = "ASTA_PLASTICA_DX";
const animaAstaPlasticaName = "ASTA_PLASTICA_DX_ANIMA";
const astaMetalloName = "ASTA_METALLO_DX";
const astaMetalloTerminaleName = "ASTA_METALLO_P_DX";
async function loadTemples(scale, line) {
    currentLine = line;
    let vectorX = new Vector3();
    let vectorY = new Vector3();
    const normals = frontTemplePositionDummy.geometry.getAttribute("normal");
    const _normalMatrix = new Matrix3().getNormalMatrix(frontTemplePositionDummy.matrixWorld);
    for (let i = 0; i < normals.array.length - 3; i += 3) {
        const x = normals.array[i];
        const y = normals.array[i + 1];
        const z = normals.array[i + 2];
        const tempArray = [x, y, z].sort();
        if (tempArray[2] == x && x > 0.8) {
            vectorX = new Vector3(x, y, z);
        }
        else if (tempArray[2] == y && y > 0.8) {
            vectorY = new Vector3(x, y, z);
        }
    }
    const diffQuaternionY = new Quaternion();
    const diffQuaternionX = new Quaternion();
    diffQuaternionX.setFromUnitVectors(new Vector3(1, 0, 0).normalize(), vectorX.applyNormalMatrix(_normalMatrix).normalize());
    diffQuaternionY.setFromUnitVectors(new Vector3(0, 1, 0).normalize(), vectorY.applyNormalMatrix(_normalMatrix).normalize());
    if (templeModel.children.length == 0) {
        const templePath = (ENV.multiLineTemples) ? `${ENV.modelsUrl}${ENV.configIdentifier}/asta_${line}.gltf` : ENV.isForApp ? `${ENV.modelsUrl}${line}/asta.gltf` : `${ENV.modelsUrl}${ENV.configIdentifier}/asta.gltf`;
        // const asta = await gltfLoader.loadAsync(`${ENV.modelsUrl}asta.gltf`);
        const asta = await gltfLoader.loadAsync(templePath);
        templeModel = asta.scene;
        templesMaterial = templeModel.getObjectByName(astaPlasticaName).material;
        templeModel.castShadow = true;
        // Salvo le normali usate nel caso di cerniere incise, come nel caso di alcuni modelli thema
        cernieraNormals = templeModel.getObjectByName(cernieraName).material.normalMap;
    }
    if (!glasses.getObjectByName("RIGHT-TEMPLE-GROUP")) {
        templeRight = templeModel.clone();
        templeRight.name = "RIGHT-TEMPLE-GROUP";
        templeLeft = templeRight.clone();
        templeLeft.name = "LEFT-TEMPLE-GROUP";
        // definisco il materiale aste
        astaPlasticaRight = templeRight.getObjectByName(astaPlasticaName);
        astaPlasticaLeft = templeLeft.getObjectByName(astaPlasticaName);
        astaPlasticaRight.material = astaPlasticaLeft.material =
            templesMaterial;
        // templesMaterial.clearcoat = 0;
        templesMaterial.roughness = 1;
        astaPlasticaLeft.castShadow = astaPlasticaRight.castShadow =
            true;
        if (ENV.multiLineTemples && (currentLine == 'THEMA' || currentLine == "DF")) {
            setMetalVisibility();
        }
        // gestione abbellitore tipo A (esagonale)
        abbellitoreRight = templeRight.getObjectByName(abbellitoreName);
        abbellitoreLeft = templeLeft.getObjectByName(abbellitoreName);
        if (abbellitoreRight) {
            abbellitoreLeft.castShadow = abbellitoreRight.castShadow = true;
            abbellitoreLeft.material = abbellitoreRight.material =
                abbellitoreGunMaterial;
        }
        // gestione abbellitore tipo B (non esagonale)
        abbellitoreBRight = templeRight.getObjectByName(abbellitoreBName);
        abbellitoreBLeft = templeLeft.getObjectByName(abbellitoreBName);
        if (abbellitoreBLeft) {
            abbellitoreBLeft.castShadow = abbellitoreBRight.castShadow = true;
            abbellitoreBLeft.material = abbellitoreBRight.material =
                abbellitoreGunMaterial;
        }
        animaRight = templeRight.getObjectByName(animaAstaPlasticaName);
        animaLeft = templeLeft.getObjectByName(animaAstaPlasticaName);
        animaLeft.castShadow = animaRight.castShadow = true;
        animaLeft.material = animaRight.material = silverMaterial;
        cernieraRight = templeRight.getObjectByName(cernieraName);
        cernieraLeft = templeLeft.getObjectByName(cernieraName);
        cernieraLeft.castShadow = cernieraRight.castShadow = true;
        cernieraLeft.material = cernieraRight.material = silverMaterial;
        cernieraLeft.material.normalMap = cernieraNormals;
        // (cernieraLeft.material as MeshPhysicalMaterial).metalness = (
        //   cernieraRight.material as MeshPhysicalMaterial
        // ).metalness = 0.95;
        animaLeft.material.metalness = animaRight.material.metalness = 0.95;
        astaMetalloRight = templeRight.getObjectByName(astaMetalloName);
        astaMetalloLeft = templeLeft.getObjectByName(astaMetalloName);
        if (astaMetalloRight) {
            astaMetalloRight.castShadow = astaMetalloLeft.castShadow = true;
        }
        if (glasses) {
            glasses.add(templeLeft);
            glasses.add(templeRight);
        }
    }
    templeRight.matrix.makeRotationFromQuaternion(diffQuaternionY);
    templeRight.matrix.makeRotationFromQuaternion(diffQuaternionX);
    templeRight.matrix.setPosition(frontTemplePositionDummy.position.multiply(scale));
    templeLeft.matrix.makeRotationFromQuaternion(diffQuaternionY.invert());
    templeLeft.matrix.makeRotationFromQuaternion(diffQuaternionX.invert());
    templeLeft.matrix.setPosition(frontTemplePositionDummy.position.multiply(new Vector3(-1, 1, 1)));
    templeLeft.matrix.scale(new Vector3(-1, 1, 1));
    templeRight.matrixAutoUpdate = false;
    templeLeft.matrixAutoUpdate = false;
    // this.setTemplesMaterial(materialeAste);
    //  verifico il materiale dell'asta e nascondo quella non necessaria
    // i nomi dei componenti metallo da nascondere sono:
    // - ASTA_METALLO_DX
    // - ASTA_METALLO_P_DX
    //  mentra per l'asta in plastica sono:
    // ASTA_PLASTICA_DX
    // ASTA_PLASTICA_DX_ANIMA
    // TODO: riportare i nomi come variabili dell'oggetto canvas
    // TODO: il materiale acetate va impostato su tutte le componenti plastiche delle aste
    // (templeRight.getObjectByName(astaPlasticaName) as Mesh).material =
    //   (
    //     templeRight.getObjectByName(astaMetalloTerminaleName) as Mesh
    //   ).material = frontMaterial[0].clone();
    // (templeRight.getObjectByName(astaPlasticaName) as Mesh).visible =
    //   false;
    // (
    //   templeRight.getObjectByName(animaAstaPlasticaName) as Mesh
    // ).visible = false;
    // (templeLeft.getObjectByName(astaMetalloName) as Mesh).visible =
    //   false;
    // (
    //   templeLeft.getObjectByName(astaMetalloTerminaleName) as Mesh
    // ).visible = false;
    // frontale.scene.add(templeRight);
    // frontale.scene.add(templeLeft);
    front.renderOrder = 1;
    templeRight.renderOrder = 1;
    templeLeft.renderOrder = 1;
    astaMetalloTerminaleSx = templeLeft.getObjectByName(astaMetalloTerminaleName);
    astaMetalloTerminaleDx = templeRight.getObjectByName(astaMetalloTerminaleName);
}
function removeTemples() {
    templeLeft.clear();
    templeRight.clear();
    templeModel.clear();
    if (glasses) {
        const tr = glasses.getObjectByName('RIGHT-TEMPLE-GROUP');
        const tl = glasses.getObjectByName('LEFT-TEMPLE-GROUP');
        glasses.remove(tr);
        glasses.remove(tl);
    }
}
async function setTemplesTexture(color, frontColor, materialeAste, coloreMetalloAste) {
    if (templesMaterial) {
        templesTexture?.dispose();
        templesTextureTransmission?.dispose();
        templesTextureRoughness?.dispose();
        templesColor = color.U_THE_COLORE;
        try {
            const mainTexture = await textureLoader
                .loadAsync(
            // "colori_acetato_sito/PL0059.jpg"
            `${ENV.texturesUrl}${templesColor}.jpg`);
            mainTexture.magFilter = LinearFilter;
            mainTexture.anisotropy = 16;
            mainTexture.encoding = sRGBEncoding;
            mainTexture.wrapS = RepeatWrapping;
            mainTexture.wrapT = RepeatWrapping;
            mainTexture.rotation = Math.PI;
            mainTexture.repeat.set(1, 1);
            templesTexture = mainTexture;
            templesMaterial.map = templesTexture;
        }
        catch (error) {
            console.error(`[Canvas3d] Nessun colore asta corrispondente a ${templesColor}`, error);
        }
        try {
            const transmissionTexture = await textureLoader
                .loadAsync(`${ENV.texturesUrl}transmission/${templesColor}.jpg`);
            transmissionTexture.magFilter = LinearFilter;
            transmissionTexture.anisotropy = 16;
            transmissionTexture.encoding = sRGBEncoding;
            transmissionTexture.wrapS = RepeatWrapping;
            transmissionTexture.wrapT = RepeatWrapping;
            transmissionTexture.rotation = Math.PI;
            transmissionTexture.repeat.set(1, 1);
            // Reset transmission texture
            if (templesTextureTransmission) {
                templesTextureTransmission.dispose();
                templesMaterial.transmissionMap = null;
                templesMaterial.transmission = 0;
            }
            templesTextureTransmission = transmissionTexture;
            templesMaterial.transmissionMap = templesTextureTransmission;
            templesMaterial.transmission = isWebgl2Supported ? ENV.isForApp ? 0.7 : 1.0 : 0;
            templesMaterial.opacity = 1;
        }
        catch (error) {
            templesTextureTransmission?.dispose();
            templesMaterial.transmissionMap = null;
            templesMaterial.transmission = 0;
            console.warn(`[Canvas3D] Nessuna texture transmission per il colore aste ${templesColor}`, error);
        }
        try {
            const roughnessTexture = await textureLoader
                .loadAsync(`${ENV.texturesUrl}transmission/${templesColor}_roughness.jpg`);
            roughnessTexture.magFilter = LinearFilter;
            roughnessTexture.anisotropy = 16;
            roughnessTexture.encoding = sRGBEncoding;
            roughnessTexture.wrapS = RepeatWrapping;
            roughnessTexture.wrapT = RepeatWrapping;
            roughnessTexture.rotation = Math.PI;
            roughnessTexture.repeat.set(1, 1);
            // Reset roughness texture
            if (templesTextureRoughness) {
                templesTextureRoughness.dispose();
                templesMaterial.roughnessMap = null;
            }
            templesTextureRoughness = roughnessTexture;
            templesMaterial.roughnessMap = templesTextureRoughness;
        }
        catch (error) {
            console.warn(`[Canvas3D] Nesusna texture roughness pe il colore ${templesColor}`, error);
            templesMaterial.roughnessMap = null;
            templesTextureRoughness?.dispose();
        }
    }
}
function changeMaterialeAste(newVal, coloreMetalloAste, multiLineTempleColor) {
    if (templeLeft.children.length > 0) {
        if (newVal == "PL") {
            // nascondo tutti i componenti del metallo, imposto i materiali adeguati per cerniera e abbellitore
            if (templeLeft.getObjectByName(astaMetalloName)) {
                templeLeft.getObjectByName(astaMetalloName).visible =
                    false;
                templeRight.getObjectByName(astaMetalloName).visible =
                    false;
                templeLeft.getObjectByName(astaMetalloTerminaleName).visible =
                    false;
                templeRight.getObjectByName(astaMetalloTerminaleName).visible =
                    false;
            }
            templeLeft.getObjectByName(astaPlasticaName).visible =
                true;
            templeLeft.getObjectByName(animaAstaPlasticaName).visible =
                true;
            templeRight.getObjectByName(astaPlasticaName).visible =
                true;
            templeRight.getObjectByName(animaAstaPlasticaName).visible = true;
            if (ENV.multiLineTemples && (currentLine == 'THEMA' || currentLine == "DF")) {
                setMetalVisibility();
            }
            updateMetalMaterial(!ENV.multiLineTemples || multiLineTempleColor != "GOLD");
            console.log(abbellitoreLeft, 'abbellitoreLeft');
            if (abbellitoreLeft != null)
                abbellitoreLeft.material = abbellitoreRight.material =
                    abbellitoreGunMaterial;
        }
        else {
            setMetalVisibility();
            changeColoreMetalloAste(coloreMetalloAste);
        }
    }
}
/**
 * Setta il materiale a GUN o GOLD
 * Se ENV.multiLineTemples è false, aggiorna
 * - cerniera
 * - anima
 * altrimenti aggiorna
 * - cerniera
 * - astaMetallo
 * e setta la normalMap precedentemente salvata alla cerniera
 * @param isGun Il materiale è GUN o GOLD?
 */
function updateMetalMaterial(isGun) {
    if (ENV.multiLineTemples && (currentLine == "THEMA" || currentLine == "DF")) {
        cernieraLeft.material =
            cernieraRight.material = isGun ? gunMaterial.clone() : goldMaterial.clone();
        astaMetalloRight.material =
            astaMetalloLeft.material = isGun ? gunMaterial : goldMaterial;
        cernieraLeft.material.normalMap = cernieraNormals;
        cernieraRight.material.normalMap = cernieraNormals;
    }
    else {
        cernieraLeft.material =
            cernieraRight.material =
                isGun ? gunMaterial : goldMaterial;
        if (astaMetalloLeft != null && astaMetalloRight != null) {
            astaMetalloRight.material =
                astaMetalloLeft.material =
                    isGun ? gunMaterial : goldMaterial;
        }
        if (animaLeft != null && animaRight != null) {
            animaLeft.material =
                animaRight.material = isGun ? gunMaterial : goldMaterial;
        }
    }
}
/**
 * Mostra tutti i componenti metallo, nascondi le componenti plastica
 */
function setMetalVisibility() {
    templeLeft.getObjectByName(astaPlasticaName).visible = false;
    templeRight.getObjectByName(astaPlasticaName).visible = false;
    templeLeft.getObjectByName(animaAstaPlasticaName).visible = false;
    templeRight.getObjectByName(animaAstaPlasticaName).visible = false;
    templeLeft.getObjectByName(astaMetalloName).visible = true;
    templeRight.getObjectByName(astaMetalloName).visible = true;
    templeLeft.getObjectByName(astaMetalloTerminaleName).visible = true;
    templeRight.getObjectByName(astaMetalloTerminaleName).visible = true;
    templeLeft.getObjectByName(cernieraName).visible = true;
    templeRight.getObjectByName(cernieraName).visible = true;
}
function changeColoreMetalloAste(newVal) {
    if (templeLeft.children.length > 0) {
        if (newVal == "PRO0000272") {
            // TODO: tenere in considerazione abbelliotre tipo B
            if (abbellitoreLeft && abbellitoreRight) {
                abbellitoreLeft.material = abbellitoreRight.material =
                    abbellitoreGunMaterial;
            }
            updateMetalMaterial(true);
        }
        else if (newVal == "PRO0000280") {
            updateMetalMaterial(false);
            // TODO: tenere in considerazione abbelliotre tipo B
            if (abbellitoreLeft && abbellitoreRight) {
                abbellitoreLeft.material = abbellitoreRight.material = goldMaterial;
            }
        }
    }
    // TODO: needUpdate eliminato, verificare se serve
    // pin_dx.needsUpdate = true;
    // pin_sx.needsUpdate = true;
}
async function changeFinituraAste(newVal) {
    finitura = newVal;
    if (templesMaterial) {
        templesMaterial.clearcoatRoughness =
            newVal == "M" || newVal == "N" ? acetateMaterialAttributes.clearcoatRoughnessMatt : acetateMaterialAttributes.clearcoatRoughnessShiny;
        templesMaterial.roughnessMap = null;
        try {
            const roughnessTexture = await textureLoader
                .loadAsync(`${ENV.texturesUrl}transmission/${templesColor}_roughness.jpg`);
            roughnessTexture.magFilter = LinearFilter;
            roughnessTexture.anisotropy = 16;
            roughnessTexture.encoding = sRGBEncoding;
            roughnessTexture.wrapS = RepeatWrapping;
            roughnessTexture.wrapT = RepeatWrapping;
            roughnessTexture.rotation = Math.PI;
            roughnessTexture.repeat.set(1, 1);
            templesTextureRoughness = roughnessTexture;
            templesMaterial.roughnessMap = templesTextureRoughness;
        }
        catch (error) {
            console.warn(`[Canvas3D] Nesusna texture roughness pe il colore ${templesColor}`, error);
            templesMaterial.roughnessMap = null;
            templesTextureRoughness?.dispose();
        }
    }
}
function changeAbbellitore(newVal) {
    if (!newVal) {
        if (abbellitoreBLeft) {
            abbellitoreBLeft.visible = abbellitoreBRight.visible = false;
        }
        if (abbellitoreLeft) {
            abbellitoreLeft.visible = abbellitoreRight.visible = true;
        }
        return;
    }
    if (abbellitoreLeft && abbellitoreRight)
        abbellitoreLeft.visible = abbellitoreRight.visible = false;
    if (abbellitoreBLeft && abbellitoreBRight) {
        abbellitoreBLeft.visible = abbellitoreBRight.visible = true;
        if (newVal == abbellitoreGunColorCode) {
            // PRO0000849    PT/ ABLT - 9 ABBELLITORE ALPACCA PROG.SILMAASEMA GUN
            abbellitoreBRight.material = abbellitoreBLeft.material = abbellitoreGunMaterial;
        }
        else if (newVal == abbellitoreGoldColorCode) {
            // PRO0000850    PT / ABLT - 9 ABBELLITORE ALPACCA PROG.SILMAASEMA GOLD
            abbellitoreBRight.material = abbellitoreBLeft.material = goldMaterial;
        }
    }
}
export { templeModel, templeLeft, templeRight, templesMaterial, templesTexture, templesTextureTransmission, templesTextureRoughness, animaLeft, animaRight, abbellitoreLeft, abbellitoreRight, abbellitoreBLeft, abbellitoreBRight, abbellitoreGoldColorCode, abbellitoreGunColorCode, cernieraLeft, cernieraRight, astaMetalloLeft, astaMetalloRight, astaPlasticaLeft, astaPlasticaRight, goldColorCode, gunColorCode, abbellitoreName, cernieraName, astaPlasticaName, animaAstaPlasticaName, astaMetalloName, astaMetalloTerminaleName, astaMetalloTerminaleSx, astaMetalloTerminaleDx, loadTemples, removeTemples, setTemplesTexture, changeColoreMetalloAste, changeFinituraAste, changeMaterialeAste, changeAbbellitore };
