import { LoadingManager, TextureLoader } from "three/src/Three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// Texture Loader
const textureLoader = new TextureLoader();
// Draco Loader
const dracoLoader = new DRACOLoader();
//TODO: l'ulr deve essere inserito in una configurazione globale
// const path = `${process.env.VUE_APP_PUBLIC_PATH}draco/`;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const pack = require("../../../package.json");
const path = `https://cdn.thema-optical.com/${pack.name}/v${pack.version}/draco/`;
dracoLoader.setDecoderPath(path);
dracoLoader.setDecoderConfig({ type: "js" });
// GLTF Loader
const gltfLoader = new GLTFLoader(new LoadingManager());
gltfLoader.setDRACOLoader(dracoLoader);
export { textureLoader, dracoLoader, gltfLoader };
